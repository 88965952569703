import { Component } from '@angular/core';

@Component({
  selector: 'app-oldbills',
  templateUrl: './oldbills.component.html',
  styleUrl: './oldbills.component.scss'
})
export class OldbillsComponent {

}
