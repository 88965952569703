import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';
import { getDueBillsResponse } from '../../../store/customer/consumer-selector';
import { getDueBills } from '../../../store/customer/consumer.action';
import items from 'razorpay/dist/types/items';
import { PaymentService } from '../../../core/services/payment.service';
import { AppConfigData } from '../../../core/config/appConfigData';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { RpAlertsComponent } from '../../../shared/components/rp-alerts/rp-alerts.component';
import { error } from 'node:console';


declare var Razorpay: any
@Component({
  selector: 'app-rp-postpaid-bills',
  templateUrl: './rp-postpaid-bills.component.html',
  styleUrl: './rp-postpaid-bills.component.scss'
})
export class RpPostpaidBillsComponent {
  paymentModal!: BsModalRef;
  isAllOpen: boolean = false;

  currentUser: any;
  projectId: any;
  billList: any[] = [];
  billGenerationType: any;
  undefinde: any;
  paybills: any[] = [];
  paybillAmount: number = 0;
  gascheckvalue: any;
  breadCrumbItems: Array<{}> | undefined;
  billNos: any[] = []
  walletBalance: number = 0
  userName: any;
  userPhone: any;
  userId: any;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  insufficientBalance: boolean = false;
  isWalletEnabled: boolean = false
  undefinded: any;

  private getdubBills: Subscription = new Subscription;
  constructor(private router: Router,
    private loaderService: LoaderService, private store: Store,
    private sharedService: SharedService,
    private paymentService: PaymentService,
    private modalService: BsModalService,


  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;
    this.billGenerationType = this.currentUser?.project.billGenerationType;
    this.userName = this.currentUser?.currentUserRoleDetails.userName;
    this.userId = this.currentUser?.currentUserRoleDetails.userId;
    this.userPhone = this.currentUser?.currentUserRoleDetails.phone;
    this.isWalletEnabled = this.currentUser?.project.isWalletEnabled;
    this.breadCrumbItems = [{ label: 'Postpaid bills' }];
    this.getdubBills = this.store.pipe(select(getDueBillsResponse)).subscribe({
      next: (res) => {
        // Set loading to true
        this.loaderService.setLoading(true);

        // Check if the response exists
        if (res) {
          console.log(res);
          this.paybillAmount = 0;
          this.billNos = [];

          // Process the response and build the billList
          this.billList = res.map((bill: any) => {
            // Add the bill to the list if it's not a current bill
            // if (!bill.isCurrentBill) {
            var toDay = new Date();
            toDay.setHours(0, 0, 0, 0);

            let dueDate = new Date(bill.dueDate);
            dueDate.setHours(0, 0, 0, 0);

            if (this.checkIsCurrentDate(bill)) { // check due date is done are not 
              // console.log(bill.dueDate);
              this.billNos.push(bill.billNo);

              // Add to the total pay bill amount
              this.paybillAmount += bill.totalBillAmount;
              this.paybills.push({
                consumerBillId: bill.id,
                billAmount: bill.billAmount,
                billNo: bill.billNo,
              });
            }

            // }
            return {
              ...bill,
              isdisabled: this.checkIsCurrentDate(bill),
              isChecked: this.checkIsCurrentDate(bill),
            };
          });

          this.walletBalance = this.billList[0]?.currentBalance;

          // Hide the loader when the bill list has been populated
          if (this.billList.length > 0) {
            this.loaderService.setLoading(false);
          } else {
            this.loaderService.setLoading(false);
          }

          // Log the computed values

          // console.log(this.paybillAmount);
        }
      },
      error: (err) => {
        // Log the error and hide the loader
        console.log('Error occurred: ', err);
        this.loaderService.setLoading(false);
      },
    });



  }

  checkIsCurrentDate(bill: any) {
    var toDay = new Date();
    toDay.setHours(0, 0, 0, 0); // Set to today with time reset to midnight

    let dueDate = new Date(bill?.dueDate);
    dueDate.setHours(0, 0, 0, 0); // Set dueDate with time reset to midnight

    // Check if the dueDate is before today
    if (dueDate.getTime() < toDay.getTime()) {
      return true; // If dueDate is before today, return true (enter the loop)
    } else {
      return false; // If dueDate is today or in the future, return false
    }
  }


  ngOnDestroy() {
    this.getdubBills.unsubscribe();  // Or set it to [] if you prefer an empty array
  }
  ngOnInit() {

    this.store.dispatch(getDueBills({ ProjectId: this.projectId }));
  }

  goToRecharge() {
    this.router.navigate(['/consumerWallet'])
    this.paymentModal.hide();
  }
  onCheckboxClick(event: Event, isChecked: boolean, index: number, billNo: string) {
    event.stopPropagation(); // Prevent the click event from affecting the accordion
    // Your existing logic for handling checkbox changes
    this.checkValue(isChecked, index, billNo);
  }
  checkValue(checked: boolean, i: number, billNo: any) {
    const bill = this.billList[i];
    // console.log(bill.totalBillAmount)
    const totalBillAmount = bill.totalBillAmount;
    //  console.log(totalBillAmount)
    if (checked) {
      if (!this.billNos.includes(billNo)) {
        this.billNos.push(billNo);
        this.paybillAmount = this.paybillAmount + totalBillAmount;
        this.paybills.push({ consumerBillId: bill.id, billAmount: bill.billAmount, billNo: bill.billNo })
      }
    } else {
      const index = this.billNos.indexOf(billNo);
      if (index > -1) {
        this.billNos.splice(index, 1);
        this.paybills.splice(index, 1)
        this.paybillAmount = this.paybillAmount - totalBillAmount;
      }
    }
    // console.log(this.paybillAmount);
    // console.log(this.paybills);
    //  console.log(this.billNos);
  }
  openPaymentDailog(paymentModel: any) {
    this.paymentModal = this.modalService.show(paymentModel, {
      class: 'modal-dialog-centered',
      backdrop: 'static',

    });
    //  console.log(this.paybillAmount)
    /* if (this.paybillAmount < 0) {
      this.sharedService.showMessageDialog(
        '',
        'Amounts under ₹1 should be paid using your wallet',
        SweetAlertIcon.ERROR
      );
      //console.log('Error: The bill amount cannot be less than 0.');
    } else {
      this.paymentModal = this.modalService.show(paymentModel, {
        class: 'modal-dialog-centered',
        backdrop: 'static',

      });
      //  console.log('The bill amount is valid:', this.paybillAmount);
    } */




  }
  payNow(isWallet: boolean) {
    this.loaderService.setLoading(true);
    let request = {
      billNos: this.billNos,
      ProjectId: this.projectId,
      amount: this.paybillAmount,
    }
    console.log(request)
    this.paymentService.createpOrderIdForpostpaid(request).subscribe({
      next: (response: any) => {
        const options = {
          key: AppConfigData.Razorpay_KeyId, // Replace with your Razorpay Key ID
          amount: this.paybillAmount * 100, // Amount in paise
          currency: 'INR',
          name: 'Ripple',
          description: ' Wallet Recharge ',
          // order_id: 'OZf8qRyQDdzyOH',
          order_id: response.data.orderId, // This is a sample Order ID. Pass the `id` obtained in the previous step
          handler: (response: any) => {
            this.paymentModal.hide();
            // console.log(response, 'payment sucess response');
            //this.sharedService.showMessageDialog('Success', 'Payment successful!', SweetAlertIcon.SUCCESS);
            //this.loaderService.setLoading(false);
            this.OnPaymentSuccess(response, isWallet)

            //alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
          },
          prefill: {
            name: this.userName,
            // email: '',
            contact: this.userPhone
          },
          notes: {
            address: '',
            // convenience_charge: '0',
          },
          config: {
            display: {
              /*  blocks: {
                 convenience_charge: {
                   name: "Convenience Charge",
                   amount: 0 // Ensure convenience charge is 0
                 }
               }, */
              hide: [
                { method: "emi" },
                // { key: "convenience_charge" } // Hide convenience charge
              ]
            },
          },
          theme: {
            color: '#54bbf3'
          },
          modal: {
            escape: false,
            ondismiss: () => {
              this.paymentModal.hide();
              console.log('orignal payment cancelled');
              this.OnPaymentFailure(response.data.orderId)
              this.loaderService.setLoading(false);
              this.sharedService.showMessageDialog('Failure', 'payment cancelled', SweetAlertIcon.ERROR);
            }
          },
        };
        const rzp1 = new Razorpay(options);
        rzp1.open();
      }, error: (error: any) => {
        this.paymentModal.hide();
        this.loaderService.setLoading(false);
        console.log(error)
        this.sharedService.showMessageDialog('Failure', 'payment cancelled', SweetAlertIcon.ERROR);
      }
    })
  }
  paymentlaertModal!: BsModalRef;

  OnPaymentSuccess(payment: any, isWallet: boolean) {
    this.loaderService.setLoading(true)
    let request = {
      consumerId: this.userId,
      projectId: this.projectId,
      paymentId: payment != this.undefinded ? payment?.razorpay_payment_id : '',
      orderId: payment != this.undefinded ? payment?.razorpay_order_id : '',
      bills: this.paybills,
      isWallet: isWallet,
      totalPayBillAmount: this.paybillAmount,
      paymentLink: ""
      //signature: payment.razorpay_signature
    }

    console.log(request)
    this.paymentService.payBill(request).subscribe({

      next: (response: any) => {
        this.paymentModal.hide();
        console.log(response);
        // let paymentData = response.data;
        this.loaderService.setLoading(false)
        let paymentData = { ...response.data };

        paymentData['paymentId'] = payment.razorpay_payment_id
        paymentData['lastRechargeDate'] = paymentData.paidDate;
        paymentData['name'] = paymentData.consumerName;
        paymentData['lastRechargeAmount'] = paymentData.totalAmount;
        paymentData['IsBill'] = true;
        console.log(response)

        // this.sharedService.showMessageDialog('Success', 'Payment successful!', SweetAlertIcon.SUCCESS);
        this.paymentlaertModal = this.modalService.show(RpAlertsComponent, {
          initialState: { paymentData },
          ...this.config,
        });

        this.store.dispatch(getDueBills({ ProjectId: this.projectId }));
      }, error: (error: any) => {
        this.loaderService.setLoading(false);
        this.paymentModal.hide();
        this.sharedService.showMessageDialog(error.error.message, '', SweetAlertIcon.ERROR);
        console.log(error)
      }
    })
  }
  OnPaymentFailure(orderId: any) {
    let request = {
      OrderId: orderId
    }
    this.paymentService.paymentBillingFailure(request).subscribe({
      next: (response: any) => {
        console.log(response)
      }, error: (error: any) => {
        console.log(error)
      }
    })
  }
  handleAccordionToggle(event: any) {
    console.log(event)
    this.isAllOpen = false;
  }
  preventAccordionToggle(event: MouseEvent): void {
    // Add logic to determine if the accordion should not open
    event.stopPropagation();
  }
}
