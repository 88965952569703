<div class="container-fluid">

  <div class="row d-flex justify-content-end ">
    <div class="col-9">
      <div class="row page-title-box d-sm-flex align-items-center justify-content-between">
        <div class="col-10">
          <h4 class="mb-0 font-size-18">Welcome to Ripple Dashboard!</h4>
        </div>
      </div>
    </div>
    <div class="col-3 float-end">
      <div class="d-sm-flex">
        <div class="col-10">
          <h4 class=" font-size-16">Project Start Date:{{ projectStartDate!= null?
            (projectStartDate |date: 'dd MMM yyyy' )
            :'' }}
          </h4>

        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div *ngIf="isWalletEnabled" class="row">
      <div class="col-lg-4">
        <div class="position-relative d-flex justify-content-center">
          <div class="prepaid-dashboard-image">
            <img src="../../../assets/images/wallet-balance.png">
          </div>
        </div>
        <div class="card mini-stats-wid mini-stats-wid-dashboard ">
          <div class="px-3 py-2">
            <div class="card-details d-flex justify-content-center text-center mb-3">
              <div (click)="goToWalletReport()">
                <p class="mb-0 mb-2 font-size-18 cursor-pointer">Total bill Amount &nbsp;<span
                    class="text-danger font-size-10">(Till
                    Date)</span>
                <h4 class="font-weight-bold-dashboard text-truncate cursor-pointer mb-0  align-items-center">
                  <!--  <span>₹</span> -->
                  {{deductedWalletBalance !=undefined ? (deductedWalletBalance | currency: 'INR':'symbol':'1.2-2'):''}}
                </h4>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="position-relative d-flex justify-content-center">
          <div class="prepaid-dashboard-image">
            <img src="../../../assets/images/current-balance.png">
          </div>
        </div>
        <div class="card mini-stats-wid mini-stats-wid-dashboard ">
          <div class="px-3 py-2">
            <div class="card-details d-flex justify-content-center text-center mb-3">
              <div (click)="goToWallet()" class="text-center">
                <p class="mb-0 mb-2 font-size-18 cursor-pointer">Current Balance &nbsp;<span
                    class="text-danger font-size-10">(Till
                    Date)</span></p>
                <h4 class="font-weight-bold-dashboard align-items-center   text-truncate mb-0 cursor-pointer">
                  <!-- <span>₹</span> -->
                  {{currentWalletBalance | currency: 'INR':'symbol':'1.2-2'}}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="position-relative d-flex justify-content-center">
          <div class="prepaid-dashboard-image">
            <img style="height: 55px;" src="../../../assets/images/money-currency_16195169.svg">
          </div>
        </div>
        <div class="card mini-stats-wid mini-stats-wid-dashboard ">
          <div class="px-3 py-2">
            <div class="card-details d-flex justify-content-center text-center mb-3">
              <div (click)="goToWalletReport()">
                <p class="mb-0 mb-2 font-size-18 cursor-pointer">Total Wallet Recharge &nbsp;<span
                    class="text-danger font-size-10">(Till
                    Date)</span></p>
                <h4 class="font-weight-bold-dashboard text-truncate mb-0  cursor-pointer ">
                  <!-- <span>₹</span> -->
                  {{totalWalletRecharge | currency: 'INR':'symbol':'1.2-2'}}
                </h4>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div class=" row mb-4">
      <div class=" d-flex justify-content-end align-items-center ">
        <div class=" me-2" *ngIf="dateRange">
          <div class=" position-relative me-2 " *ngIf="dateRange" style="width: 238px;">
            <input type="text" class="form-control date"
              [value]="filterProjectBillDates ? (formatDateRange(filterProjectBillDates)) : ''"
              placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
              [bsConfig]="datePickerConfig" [(bsValue)]="filterProjectBillDates"
              (bsValueChange)="onDateRangeSelected($event)" />
            <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
            <span class="bx bx-x inputIcon clear-icon" *ngIf="filterProjectBillDates" (click)="clearDate()"></span>
          </div>


          <!-- <input type="text" class="form-control date" placeholder="Select Date" name="daterange" autocomplete="off"
            bsDaterangepicker [bsConfig]="datePickerConfig" (bsValueChange)="onDateRangeSelected($event)" /> -->
        </div>
        <div class="d-flex">
          <div class="me-2">
            <ng-select [items]="months" (change)="selectionChange($event)" id="billingType" placeholder="Select Month"
              [(ngModel)]="selectedRange" [multiple]="false" [clearable]="false" [deselectOnClick]="false"></ng-select>
          </div>
          <!-- <div>
            <ng-select [items]="projectUtlityList" [clearable]="false" [(ngModel)]="consuptionSelectedUtility"
              placeholder="Select Utility" (change)="consuprtionUtilitiesChange($event)" id="billingType"
              [multiple]=" false" [deselectOnClick]="false" [clearable]="false" bindValue="id"
              bindLabel="name"></ng-select>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="this.billingType=='Postpaid'">
      <div class="col-xl-4" *ngFor="let item of billSummary">
        <div class="card cursor-pointer" [style.background]="item.background" (click)="billStatus(item)">
          <div class="d-flex justify-content-between p-3 align-items-center">
            <span class="fa fa-info-circle cursor-pointer position-absolute top-0 end-0 mt-2 me-2" kendoTooltip
              [title]="item.label + ' includes the total count for bills, manual bills, and adhoc bills'">
            </span>
            <img [src]="item.img" class="w-20">
            <div class="text-center border-right pr-30">
              <p class="mb-0">{{item.label}}</p>
              <h4 class="mb-0">{{item.value}}</h4>
            </div>
            <!--  <span class="fa fa-info-circle cursor-pointer" style="float: right" kendoTooltip
              [title]="item.label + ' include the total count for bills, manual bills, and adhoc bills'">
            </span> -->
            <div>
              <p class="mb-0">{{item.labelAmount}}</p>
              <h4 class="mb-0 text-truncate w-9" kendoTooltip title="{{item.amount}}">{{item.amount | currency:
                'INR':'symbol': '1.2-2'}}</h4>


            </div>
          </div>
          <div *ngIf="billSummaryLoader" class="chart-loader">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body" style="width: 100%; overflow: hidden">
            <!--  <div *ngIf=" consumptionData.length> 0"> -->
            <div class="d-flex  justify-content-between">
              <h4 class="card-title me-3"> Consumption(Total units
                consumed:{{consumptionData.length>0?sumOfTotalConsumption:0}})</h4>
              <div class="row">
                <ng-select [items]="projectUtlityList" [clearable]="false" [(ngModel)]="consuptionSelectedUtility"
                  placeholder="Select Utility" (change)="utilitiesChangeConsumption($event)" id="billingType"
                  [multiple]=" false" [deselectOnClick]="false" [clearable]="false" bindValue="id"
                  bindLabel="name"></ng-select>

              </div>
              <!--  <div class="row">
                <h4 class="card-title me-3"> Total units consumed:{{consumptionData.length>0?sumOfTotalConsumption:0}}
                </h4>

              </div> -->
            </div>
            <!-- <div class="d-flex justify-content-between align-items-center">
              <h4 class="card-title mb-3"> Consumption </h4>
              
            </div> -->

            @if(isdailyConsumptionChart){<div style="overflow-x: auto">
              <div *ngIf="consumptionLoader" class="chart-loader">
                <div class="spinner"></div>
              </div>

              <div *ngIf="consumptionData &&  consumptionData.length > 0">

                <apx-chart #chart [series]="chartOptions.series" class="cursor-pointer "
                  style="width: 99%; display:block" [chart]="chartOptions.chart"
                  (dataPointSelection)="onDataPointSelection($event)" [colors]="chartOptions.colors"
                  [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels" [markers]="chartOptions.markers"
                  [stroke]="chartOptions.stroke" [xaxis]="chartOptions.xaxis" [tooltip]="chartOptions.tooltip"
                  [annotations]="chartOptions.annotations"></apx-chart>


                <div class="d-flex justify-content-end text-danger">
                  <span>*{{consuptionSelectedUtilityType?.name =='Water'?
                    consuptionSelectedUtilityType?.name +
                    ' in Liters':
                    consuptionSelectedUtilityType?.name =='Electricity'? consuptionSelectedUtilityType?.name +' in kWh':
                    consuptionSelectedUtilityType?.name +' in m³'}} </span>
                  <!-- <span>* {{selectedUtility}} units in liters </span> -->
                </div>
              </div>
            </div>

            <div *ngIf="consumptionData &&consumptionData.length==0"
              class=" card-no-data text-center d-flex justify-content-center align-items-center superadminGraph">
              <p>No data available</p>
            </div>}



            @else {
            <div *ngIf="monthlyConsumptionLoader" class="chart-loader">
              <div class="spinner"></div>
            </div>
            <div *ngIf="monthlyConsumptionchartData && monthlyConsumptionchartData.length">
              <kendo-chart style="height: 264px;">
                <kendo-chart-series>
                  <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [title]="{ text: 'Months' }">
                      <!-- X-axis title set to 'Flats' -->
                    </kendo-chart-category-axis-item>
                  </kendo-chart-category-axis>

                  <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [title]="{ text: 'Units' }">
                      <!-- Y-axis title set to 'Units' -->
                    </kendo-chart-value-axis-item>
                  </kendo-chart-value-axis>
                  <kendo-chart-series-item [data]="monthlyConsumptionchartData" field="value" categoryField="category"
                    [colorField]="'colorString'" [labels]="{ visible: true, content: labelContent }"
                    [visible]="seriesVisible" [tooltip]="{ visible: true }" [spacing]="1">
                  </kendo-chart-series-item>
                </kendo-chart-series>

                <kendo-chart-legend position="bottom"></kendo-chart-legend>

                <!-- <kendo-chart-tooltip format="Units">
                  <ng-template kendoChartTooltipTemplate let-tooltip="tooltip">
                    <div>
                      <strong>{{ tooltip.series.name }}</strong><br />
                      Month: {{ tooltip.category }}<br />
                      Value: {{ tooltip.value}}Units
                    </div>
                  </ng-template>
                </kendo-chart-tooltip> -->
              </kendo-chart>
              <div class="d-flex justify-content-end text-danger">
                <span>*{{consuptionSelectedUtilityType?.name =='Water'?
                  consuptionSelectedUtilityType?.name +
                  ' in Liters':
                  consuptionSelectedUtilityType?.name =='Electricity'? consuptionSelectedUtilityType?.name +' in kWh':
                  consuptionSelectedUtilityType?.name +' in m³'}} </span>
                <!-- <span>* {{selectedUtility}} units in liters </span> -->
              </div>

            </div>
            <div *ngIf="monthlyConsumptionchartData &&monthlyConsumptionchartData.length==0"
              class=" card-no-data text-center d-flex justify-content-center align-items-center superadminGraph">
              <p>No data available</p>
            </div>}
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">

            <div class="d-flex  justify-content-between">
              <h4 class="card-title me-3" *ngIf="consumptionminmaxvalue=='1'"> Top 5 consumption</h4>
              <h4 class="card-title me-3" *ngIf="consumptionminmaxvalue=='2'"> Least 5 consumption</h4>
              <div class="row">
                <div class="col">
                  <ng-select [items]="maxminlist" [(ngModel)]="consumptionminmaxvalue" placeholder="Select Max/Min "
                    class="" (change)="maxMinValucChange($event)" id="billingType" [multiple]=" false"
                    [deselectOnClick]="false" [clearable]="false" bindValue="id" bindLabel="name"></ng-select>
                </div>
                <div class="col">
                  <ng-select [items]="projectUtlityList" [clearable]="false" [(ngModel)]="topconsuptionSelectedUtility"
                    placeholder="Select Utility" (change)="utilitiesChange($event)" id="billingType" [multiple]=" false"
                    [deselectOnClick]="false" [clearable]="false" bindValue="id" bindLabel="name"></ng-select>
                </div>
              </div>

            </div>


            <div *ngIf="topconsumptionLoader" class="chart-loader">
              <div class="spinner"></div>
            </div>

            <div *ngIf=" topconsumptionData && topconsumptionData.length>0">
              <kendo-chart style="height: 250px;" class="mt-3 cursor-pointer"
                (seriesClick)="top5ConsumptionClick($event)">
                <kendo-chart-series>
                  <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [title]="{ text: 'Flats' }">
                      <!-- X-axis title set to 'Flats' -->
                    </kendo-chart-category-axis-item>
                  </kendo-chart-category-axis>

                  <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [title]="{ text: 'Units' }">
                      <!-- Y-axis title set to 'Units' -->
                    </kendo-chart-value-axis-item>
                  </kendo-chart-value-axis>
                  <kendo-chart-series-item [data]="topconsumptionData" type="bar" field="value" categoryField="category"
                    [labels]="{ visible: true, content: labelContent, font: '12px Arial',  rotation: -40, padding: -3 }"
                    [colorField]="'colorString'">
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>


              <div class="d-flex justify-content-end text-danger">
                <span>*{{topconsuptionSelectedUtilityType?.name =='Water'? topconsuptionSelectedUtilityType?.name
                  +' in Liters':
                  topconsuptionSelectedUtilityType?.name =='Electricity'? topconsuptionSelectedUtilityType?.name
                  +' in kWh':
                  topconsuptionSelectedUtilityType?.name +' in m³'}} </span>
              </div>
            </div>

            <div *ngIf=" topconsumptionData && topconsumptionData.length==0"
              class="card-no-data   d-flex justify-content-center align-items-center text-center superadminGraph">
              <p>No data available</p>
            </div>


          </div>

        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-xl-3">
        <div class="card">
          <div class="card-body">
            <div class="">

              <h4 class="card-title mb-0">
                Meter Communication Status
                <!--  <span class="fa fa-info-circle" *ngIf="selectedmeterCommunicationutility !== 'Electricity'"
                  [attr.title]="meterCommunication_title[0]?.title"></span>
                <span class="fa fa-info-circle" *ngIf="selectedmeterCommunicationutility === 'Electricity'"
                  [attr.title]="meterCommunication_title[1]?.eleTitle"></span> -->
              </h4>


              <!--  <h4 class="card-title mb-0">
                Meter Communication Status
                <i class="fa fa-info-circle" *ngIf="selectedmeterCommunicationutility !== 'Electricity'"
                  data-toggle="tooltip" data-placement="right" title="{{title}}" class="info-icon"></i>
                <i class="fa fa-info-circle" *ngIf="selectedmeterCommunicationutility == 'Electricity'"
                  data-toggle="tooltip" data-placement="right" title="{{eleTitle}}" class="info-icon"></i>
              </h4> -->


              <div class="d-flex justify-content-end mt-2">
                <ng-select class="w-50" placeholder="Select utilities" [items]=" projectUtlityList"
                  placeholder="Select Utility" [(ngModel)]="meterUtilityType" (change)="utilityMeterChange($event)"
                  id="billingType" [multiple]="false" [clearable]="false" [deselectOnClick]="false" bindValue="id"
                  bindLabel="name"></ng-select>
              </div>
              <div class="row" *ngIf="meterCommunicationStatus">
                <div class="col-xl-12">
                  <kendo-chart [style.height.px]="chartHeight" class="cursor-pointer"
                    (seriesClick)="onMeterCommunicationStatusClick($event)">
                    <kendo-chart-series>
                      <kendo-chart-series-item *ngFor="let item of communicationStatusGraphData" [data]="item.data"
                        [name]="item.name" [color]="item.color" [stack]="{ group: item.stack }" type="column">
                        <!--   </kendo-chart-series-item>
                    </kendo-chart-series> -->

                        <kendo-chart-tooltip format="{0}">
                          <ng-template kendoChartTooltipTemplate let-tooltip="tooltip">
                            <div>
                              <strong>{{ tooltip.series.name }}</strong>
                              Month: {{ tooltip.category }}
                              Value: {{ tooltip.value }}
                            </div>
                          </ng-template>
                        </kendo-chart-tooltip>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                  </kendo-chart>
                </div>

                <div class="my-2">
                  <div *ngFor="let series of communicationStatusGraphData">
                    <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i>
                      <span class="font-size-12 font-weight-bolder"> {{series?.name}}
                        <span class="fa fa-info-circle cursor-pointer" kendoTooltip [attr.title]="series.comment"
                          [position]="'right'"></span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

            </div>

            <div *ngIf="!meterCommunicationStatus"
              class="meter-no-data text-center d-flex justify-content-center align-items-center superadminGraph ">
              <p>No data available</p>
            </div>
          </div>

        </div>
      </div>

      <div class="col-xl-3">
        <div class="card">
          <div class="card-body">
            <div class="">
              <h4 class="card-title mb-0">Meter Status</h4>

              <div class="d-flex justify-content-end mt-2">
                <ng-select class="w-50" placeholder="Select utilities" [items]=" projectUtlityList"
                  placeholder="Select utilities" [(ngModel)]="meterStatusUtility"
                  (change)="MeterStatusUtilityChange($event)" id="billingType" [multiple]=" false"
                  [deselectOnClick]="false" bindValue="id" [clearable]="false" bindLabel="name"></ng-select>
              </div>
              <div class="row" *ngIf="communicationMeterStatusGraphData && communicationMeterStatusGraphData.length>0">
                <div class="col-xl-12">
                  <kendo-chart class="cursor-pointer" style="height: 254px;" (seriesClick)="onMeterStatusClick($event)">
                    <kendo-chart-series>
                      <kendo-chart-series-item type="donut" [data]="communicationMeterStatusGraphData">
                        <kendo-chart-series-item-labels [content]="labelContent" color="#fff" [visible]="true"
                          position="center" background="transparent">
                        </kendo-chart-series-item-labels>
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                  </kendo-chart>
                  <div class="d-flex justify-content-between  mt-2">
                    <div>
                      <div class="" *ngFor="let series of communicationMeterStatusGraphData">
                        <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                            class="font-size-10 font-weight-bolder ms-1"> {{series.name}}</span></span>
                      </div>
                    </div>

                  </div>
                  <h4 class="card-title mb-0 float-end ">Total : <span class="font-weight-bolder"> {{totalMeterStatus}}
                    </span>
                  </h4>
                </div>
              </div>

            </div>

            <div *ngIf="communicationMeterStatusGraphData.length==0"
              class="meterStatus-no-data text-center d-flex justify-content-center align-items-center superadminGraph">
              <p>No data available</p>
            </div>
          </div>

        </div>
      </div>

      <div class="col-xl-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div class="d-flex justify-content-between">
                <h4 class="card-title mb-0">Residential Units </h4>
              </div>
            </div>
            <div *ngIf="residentialUnitsData && residentialUnitsData?.length>0">
              <div class="d-flex justify-content-end mt-2" style="visibility:hidden">
                <ng-select class="w-50" placeholder="Select utilities" [items]=" projectUtlityList"
                  placeholder="Select utilities" id="billingType" [multiple]=" false" [deselectOnClick]="false"
                  bindValue="id" [clearable]="false" bindLabel="name"></ng-select>
              </div>
              <kendo-chart style="height: 254px;" class="cursor-pointer"
                (seriesClick)="onResidentialUnitsClick($event)">
                <kendo-chart-series>
                  <kendo-chart-series-item type="pie" [data]="residentialUnitsData">
                    <kendo-chart-series-item-labels [content]="labelContent" color="#fff" [visible]="true"
                      position="center" background="transparent">
                    </kendo-chart-series-item-labels>
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
              <div class="d-flex justify-content-between  mt-2">
                <div>
                  <div *ngFor="let series of residentialUnitsData">
                    <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                        class="font-size-12 font-weight-bolder ms-1"> {{series.name}} </span></span>
                  </div>
                </div>

              </div>
              <h4 class="card-title mb-0 float-end">Total : <span class="font-weight-bolder"> {{totalResidentialUnits}}
                </span>
              </h4>
            </div>
            <div *ngIf="residentialUnitsData && residentialUnitsData?.length==0"
              class="residential-no-data text-center d-flex justify-content-center align-items-center superadminGraph">
              <p>No data available</p>
            </div>
          </div>
        </div>
      </div>


      <div class="col-xl-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mb-0">Consumers</h4>
            </div>
            <div *ngIf="consumerData && consumerData.length>0">
              <div class="d-flex justify-content-end mt-2" style="visibility:hidden">
                <ng-select class="w-50" placeholder="Select utilities" [items]=" projectUtlityList"
                  placeholder="Select utilities" id="billingType" [multiple]=" false" [deselectOnClick]="false"
                  bindValue="id" [clearable]="false" bindLabel="name"></ng-select>
              </div>
              <kendo-chart style="height: 254px;" class="cursor-pointer" (seriesClick)="onConsumersClick($event)">
                <kendo-chart-series>
                  <kendo-chart-series-item type="pie" [data]="consumerData">
                    <kendo-chart-series-item-labels [content]="labelContent" color="#fff" [visible]="true"
                      position="center" background="transparent">
                    </kendo-chart-series-item-labels>
                  </kendo-chart-series-item>

                </kendo-chart-series>
              </kendo-chart>
              <div class="d-flex justify-content-between  mt-2">
                <div>
                  <div class="" *ngFor="let series of consumerData">
                    <span class=""><i class="bx bxs-circle" [ngStyle]="{'color': series.color}"></i><span
                        class="font-size-12 font-weight-bolder ms-1"> {{series.name}} </span></span>
                  </div>
                </div>

              </div>
              <h4 class="card-title mb-0 float-end">Total : <span class="font-weight-bolder"> {{totalConsumers}} </span>
              </h4>
            </div>
            <div *ngIf="consumerData && consumerData.length==0"
              class="consumer-no-data text-center d-flex justify-content-center align-items-center superadminGraph">
              <p>No data available</p>
            </div>
          </div>
        </div>
      </div>


    </div>


  </div>