import { Component, ViewChild } from '@angular/core';

import { from } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';
import { getmeterDeatailsResponse, updateConsumerMeterDetailssuccessResponse } from '../../../store/customer/consumer-selector';
import { getmeterDeatails, resetupdateConsumerMeterDetails, updateConsumerMeterDetails } from '../../../store/customer/consumer.action';
import { DragOverEvent, NavigateEvent, SortableComponent } from '@progress/kendo-angular-sortable';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Subscription } from 'rxjs';

import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import items from 'razorpay/dist/types/items';
@Component({
  selector: 'app-rp-meter-details',
  templateUrl: './rp-meter-details.component.html',
  styleUrl: './rp-meter-details.component.scss'
})
export class RpMeterDetailsComponent {
  @ViewChild('sortable') public sortable!: SortableComponent;
  isAllOpen: boolean = true;
  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  meterDetails: any;
  flatwWiseMetersdeatils: any[] = []
  water: string = '';
  gas: string = '';
  currentUser: any;
  residentialUnitId: any
  consumerId: any
  electricity: string = '';
  sortBy: any[] = []
  breadCrumbItems: Array<{}> | undefined;
  selectedflatwWiseMetersdeatils: any[] = []
  private updatemerterdetailssubscription: Subscription = new Subscription();
  modifiedInputFields: any[] = []
  public inputFields = [
    { label: 'Flat/House#', name: 'Flat_House', value: '' },
    { label: 'Floor', name: 'Floor', value: '' },
    { label: 'Tower', name: 'Tower', value: '' },
    { label: 'Block', name: 'Block', value: '' },
  ];
  constructor(private router: Router,
    private loaderService: LoaderService, private store: Store,
    private sharedService: SharedService
  ) {
    this.breadCrumbItems = [{ label: 'Meter Details' }];
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.consumerId = this.currentUser?.currentUserRoleDetails?.userId;

    this.getBillsStore()
  }

  ngOnInit(): void {
    this.store.dispatch(getmeterDeatails());

  }

  public onDragOver(event: DragOverEvent): void {
    event.preventDefault();
    this.sortable.moveItem(event.oldIndex, event.index);
    this.isButtonEnabled = true
  }

  getBillsStore() {
    this.store.dispatch(resetupdateConsumerMeterDetails());

    let getmeterDeatailsResponse$ = this.store.pipe(select(getmeterDeatailsResponse));

    this.updatemerterdetailssubscription = this.store.pipe(select(updateConsumerMeterDetailssuccessResponse)).subscribe({
      next: (res: any) => {
        console.log('res', res);
        if (res) {

          this.inputFields.forEach((field) => {
            if (field.name == 'Flat_House') {
              field.value = res.data.flatNo;
            }
            if (field.name == 'Floor') {
              field.value = res.data.floor;
            }
            if (field.name == 'Tower') {
              field.value = res.data.tower;
            }
            if (field.name == 'Block') {
              field.value = res.data.block;
            }
          });
          console.log(this.inputFields)
          if (res && res.data && res.data.sortOrder !== '') {
            //console.log('sortOrder:', res.data.sortOrder);  // Accessing res.data.sortOrder
            const sortOrderString = res.data.sortOrder.split(',');  // Split the sortOrder

            this.inputFields.sort((a, b) => {
              // Check if both a.name and b.name exist in sortOrderString
              const aIndex = sortOrderString.indexOf(a.name);
              const bIndex = sortOrderString.indexOf(b.name);

              // If either of them is not found, it will return -1, but if both are not found, they are placed at the end
              return aIndex === -1 ? (bIndex === -1 ? 0 : 1) : (bIndex === -1 ? -1 : aIndex - bIndex);
            });

            this.flatwWiseMetersdeatils.forEach((item: any) => {
              if (item.id == res?.residentialUnitId) {
                item.modifiedInputFields = this.inputFields
              }
            })
            // this.modifiedInputFields = this.inputFields


            // console.log(this.inputFields);
            // console.log(this.modifiedInputFields);
          }

          this.sharedService.showMessageDialog(
            'Success',
            res.message,
            SweetAlertIcon.SUCCESS
          );
        }
      }
    })


    getmeterDeatailsResponse$.subscribe((res: any) => {
      console.log('res', res);
      if (res) {
        this.flatwWiseMetersdeatils = res;
        // this.residentialUnitId = res.id;
        console.log(this.flatwWiseMetersdeatils)


        this.inputFields = [
          { label: 'Flat/House#', name: 'Flat_House', value: '' },
          { label: 'Floor', name: 'Floor', value: '' },
          { label: 'Tower', name: 'Tower', value: '' },
          { label: 'Block', name: 'Block', value: '' },
        ];
        this.flatwWiseMetersdeatils = this.flatwWiseMetersdeatils.map((item: any) => {
          // Update the input fields based on the item properties
          this.inputFields.forEach((field) => {
            if (field.name === 'Flat_House') {
              field.value = item.flatNo;
            }
            if (field.name === 'Floor') {
              field.value = item.floor;
            }
            if (field.name === 'Tower') {
              field.value = item.tower;
            }
            if (field.name === 'Block') {
              field.value = item.block;
            }
          });

          // If sortOrder is provided, sort the input fields
          if (item.sortOrder !== '') {
            const sortOrderString = item.sortOrder.split(',');

            // Sort the input fields based on the sortOrderString
            this.inputFields.sort((a, b) => {
              const aIndex = sortOrderString.indexOf(a.name);
              const bIndex = sortOrderString.indexOf(b.name);

              // If either aIndex or bIndex is -1 (not found), place it at the end
              return aIndex === -1 ? (bIndex === -1 ? 0 : 1) : (bIndex === -1 ? -1 : aIndex - bIndex);
            });

            // Create a new object with modifiedInputFields added
            return {
              ...item,  // Spread the existing properties of the item
              modifiedInputFields: [...this.inputFields], // Add modifiedInputFields property
            };
          } else {
            // If no sortOrder, just add modifiedInputFields to the item
            return {
              ...item,
              modifiedInputFields: [...this.inputFields],
            };
          }
        });


        console.log(this.modifiedInputFields)
        // console.log(this.inputFields);
        console.log("fienal data", this.flatwWiseMetersdeatils);
        // console.log(this.flatwWiseMetersdeatils.length)
        if (this.flatwWiseMetersdeatils.length > 0) {
          //  console.log("length", this.flatwWiseMetersdeatils.length)
          this.tabChange({
            selectedIndex
              :
              0
          })
        }
      } else {
        this.meterDetails = {}
        this.flatwWiseMetersdeatils = [];
        console.log('res', res);
      }
    })
  }


  tabChange(event: any) {
    this.selectedflatwWiseMetersdeatils = []
    this.selectedflatwWiseMetersdeatils.push(this.flatwWiseMetersdeatils[event.selectedIndex]);
    if (this.selectedflatwWiseMetersdeatils.length > 0) {
      this.water = this.selectedflatwWiseMetersdeatils[0]?.water.length > 0 ? `Water ( ${this.selectedflatwWiseMetersdeatils[0]?.water.length})` : ''
      this.gas = this.selectedflatwWiseMetersdeatils[0]?.gas.length > 0 ? `Gas ( ${this.selectedflatwWiseMetersdeatils[0]?.gas.length})` : ''
      this.electricity = this.selectedflatwWiseMetersdeatils[0]?.electricity.length > 0 ? `Electricity ( ${this.selectedflatwWiseMetersdeatils[0]?.electricity.length})` : ''
    }




    console.log("selected this", this.selectedflatwWiseMetersdeatils);

  }
  ngOnDestroy() {
    this.updatemerterdetailssubscription.unsubscribe();
  }


  public onNavigate(event: NavigateEvent): void {
    this.sortable.moveItem(event.oldIndex, event.index);
  }
  public isButtonEnabled: boolean = false;

  updateMeters(data: any) {
    console.log(data)
    this.sortBy = []
    data?.modifiedInputFields?.forEach((field: any) => {
      this.sortBy.push(field.name);
    })
    console.log(this.sortBy);
    let request = {
      consumerId: this.consumerId,
      sortOrder: this.sortBy.toString(),
      ResidentialUnitId: data.id

    }
    this.store.dispatch(updateConsumerMeterDetails({ updaedMeterData: request }));

  }



}
