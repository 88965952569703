<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    @if(cdkStepper.selectedIndex === 0){<button class="btn btn-primary d-flex align-items-center"
                        (click)="exportAllBills()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}
                    @if(cdkStepper.selectedIndex === 1){<button class="btn btn-primary d-flex align-items-center"
                        (click)="exportAllManual()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}
                    @if(cdkStepper.selectedIndex === 2){<button class="btn btn-primary d-flex align-items-center"
                        (click)="exportAllAdhoc()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}

                </span>

            </div>
        </div>
    </div>

    <!-- <div class="row">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    </div> -->

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-stepper #cdkStepper [linear]="true" class="wizard" (selectionChange)="tabChange($event)">

                        <cdk-step [optional]="false">
                            <ng-template cdkStepLabel>
                                <span class="number">1</span>
                                <span>Bills</span>{{isReleasebills}}
                            </ng-template>
                            @if(cdkStepper.selectedIndex === 0){

                            <section class="">
                                <ng-template #template let-anchor>
                                    <span>{{ anchor.nativeElement.innerText }}</span>
                                </ng-template>

                                <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                    (mouseover)="showTooltip($event)">
                                    <kendo-grid [data]="billstgridData" [reorderable]="true" filterable="menu"
                                        [columnMenu]="{ filter: true }" scrollable="scrollable" [resizable]="true"
                                        [pageable]="{
                                            buttonCount: buttonCount,
                                                   info: info,
                                                   type: type,
                                                    pageSizes: billpageSizes,
                                             previousNext: billpreviousNext
                                         }" [pageSize]="pageSize" [sortable]="true" [selectable]="selectableSettings"
                                        [filter]="bill.filter" [skip]="skip" [sort]="bill.sort"
                                        (filterChange)="onbillsFilterChange($event)"
                                        (sortChange)="onbillsSortChange($event)" [resizable]="true"
                                        (pageChange)="billsPageChange($event)"
                                        (dataStateChange)="billsStateChange($event)"
                                        (selectionChange)="billsonSelect($event)">
                                        <ng-template kendoGridToolbarTemplate>
                                            <div class="search_icon">
                                                <kendo-textbox [style.width.px]="250" [style.height.px]="35"
                                                    [(ngModel)]="billsearchedValue" [clearButton]="true"
                                                    (valueChange)="billValueChange($event)"
                                                    (keydown.enter)="onSearchConsumers()"
                                                    placeholder="Search in all columns...">
                                                </kendo-textbox>

                                                <button class="btn btn-primary btn-md border-left-none"
                                                    (click)="bilsonFilter($event)">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div>

                                            <kendo-grid-spacer></kendo-grid-spacer>
                                            <button class="btn btn-outline-primary btn-sm" (click)="releaseBills()"
                                                [disabled]="isReleasebills">Release Bills
                                            </button>
                                            <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                (click)="onBillsExport('Excel')">
                                                <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                            </button>

                                        </ng-template>

                                        <!-- Pdf   start-->
                                        <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                            <kendo-grid
                                                [kendoGridBinding]="billselectedRowdata.length>0 ? billselectedRowdata:billstgridData.data"
                                                #BillspdfGrid>
                                                <kendo-grid-column field="billNo" title="Bill No"></kendo-grid-column>
                                                <kendo-grid-column field="residentialUnit"
                                                    title="Residential Unit"></kendo-grid-column>
                                                <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                                <kendo-grid-column field="macAddress" title="Meter No">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="billAmount" title="Bill Amount">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="modeOfPayment" title="Mode of Payment">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="isReleased"
                                                    title="Released Status "></kendo-grid-column>
                                                <kendo-grid-column field="transactionId" title="Payment Id">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="paymentDate" title="Payment Date"
                                                    filter="date">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="billGeneratedDate" title="Bill Generated Date"
                                                    filter="date">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="billMonth"
                                                    title="Bill Month"></kendo-grid-column>
                                                <kendo-grid-column field="paymentStatus" title="Status">
                                                </kendo-grid-column>
                                                <kendo-grid-excel fileName="Bills.xlsx"></kendo-grid-excel>
                                                <kendo-grid-pdf fileName="Billspdf" [allPages]="true" paperSize="A4"
                                                    [landscape]="true"></kendo-grid-pdf>
                                            </kendo-grid>
                                        </div>
                                        <!-- Pdf Edrt-->

                                        <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                            class="no-tooltip" [width]="40"></kendo-grid-checkbox-column>
                                        <kendo-grid-column field="billNo" title="Bill No"></kendo-grid-column>
                                        <kendo-grid-column field="residentialUnit"
                                            title="Residential Unit"></kendo-grid-column>
                                        <kendo-grid-column field="consumerName" title="Consumer"></kendo-grid-column>
                                        <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                        <kendo-grid-column field="macAddress" title="Meter No"
                                            [hidden]="true"></kendo-grid-column>
                                        <kendo-grid-column field="billAmount" title="Bill Amount">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{ dataItem.billAmount | currency: 'INR':'symbol':'1.2-2' }}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="paidDate" title="Payment Date" filter="date">
                                            <!--  <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.paidDate =='' ?'':
                                                dataItem.paidDate | date: 'dd MMM yyyy hh:mm a'}}

                                            </ng-template> -->
                                        </kendo-grid-column>
                                        <kendo-grid-column field="billGeneratedDate" title="Bill Generated Date"
                                            filter="date">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.billGeneratedDate =='' ?'':
                                                dataItem.billGeneratedDate |
                                                date: 'dd MMM yyyy hh:mm a'}}

                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="billMonth" title="Bill Month"></kendo-grid-column>
                                        <kendo-grid-column field="isReleased" title="Released Status " filter="boolean">
                                            <!-- <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.isReleased == true ? "Released ":" Not Released" }}
                                            </ng-template> -->

                                        </kendo-grid-column>
                                        <kendo-grid-column field="modeOfPayment" [hidden]="true"
                                            title="Mode of Payment"></kendo-grid-column>
                                        <kendo-grid-column field="transactionId" [hidden]="true"
                                            title="Payment Id"></kendo-grid-column>
                                        <kendo-grid-column field="paymentStatus" title="Status">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <ng-container
                                                    *ngIf="dataItem.paymentStatus !== undefined; else noStatus">

                                                    <ng-container
                                                        *ngIf="dataItem.paymentStatus === 'Paid'; else unpaidOrRefund">
                                                        <button type="button"
                                                            class="btn btn-soft-success waves-effect waves-light">Paid</button>
                                                    </ng-container>

                                                    <ng-template #unpaidOrRefund>
                                                        <ng-container
                                                            *ngIf="dataItem.paymentStatus === 'Unpaid'; else inProgressOrCancelled">
                                                            <button type="button"
                                                                class="btn btn-soft-danger waves-effect waves-light">Unpaid</button>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-template #inProgressOrCancelled>
                                                        <ng-container
                                                            *ngIf="dataItem.paymentStatus === 'In Progress'; else cancelled">
                                                            <button type="button"
                                                                class="btn btn-soft-warning waves-effect waves-light">In
                                                                Progress</button>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-template #cancelled>
                                                        <ng-container
                                                            *ngIf="dataItem.paymentStatus === 'Cancelled'; else refund">
                                                            <button type="button"
                                                                class="btn btn-soft-danger waves-effect waves-light">Cancelled</button>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-template #refund>
                                                        <button type="button"
                                                            class="btn btn-soft-success waves-effect waves-light">
                                                            {{ dataItem.paymentStatus }}
                                                        </button>
                                                    </ng-template>

                                                </ng-container>

                                                <ng-template #noStatus>
                                                    <!-- Optional: Show something when `paymentStatus` is undefined -->
                                                    <span>No status</span>
                                                </ng-template>
                                            </ng-template>


                                            <!-- <ng-template kendoGridCellTemplate let-dataItem>
                                                @if(dataItem.paymentStatus !==undefined){

                                                @if(dataItem.paymentStatus=='Paid'){ <button type="button"
                                                    class="btn btn-soft-success waves-effect waves-light">Paid</button>}
                                                @if(dataItem.paymentStatus=='Unpaid'){ <button type="button"
                                                    class="btn btn-soft-danger waves-effect waves-light">Unpaid</button>}

                                                
                                                }

                                            </ng-template> -->
                                        </kendo-grid-column>

                                        <kendo-grid-command-column title="" [columnMenu]="false" [width]="100">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <div class="d-flex">
                                                    <span kendoTooltip title="Go to Info" class="me-2"
                                                        (click)="viewBilsdeatils(dataItem)" style="cursor: pointer; ">
                                                        <i class="fas fa-info-circle text-success"></i>
                                                    </span>
                                                    <span kendoTooltip title="Download Bill" class="ms-2"
                                                        (click)="downloadbillsumary(dataItem)"
                                                        style="cursor: pointer; ">
                                                        <i class="fa fa-download text-success" aria-hidden="true"></i>
                                                    </span>
                                                    <span kendoTooltip title="Download receipt"
                                                        (click)="downloadBillsPaymentrecceipt(dataItem)"
                                                        *ngIf="dataItem.paymentStatus.toLowerCase() === 'paid'"
                                                        class="ms-2" style="cursor: pointer; ">
                                                        <i class="fa fa-download text-success" aria-hidden="true"></i>
                                                    </span>
                                                </div>

                                            </ng-template>
                                        </kendo-grid-command-column>

                                    </kendo-grid>
                                </div>
                            </section>
                            }
                        </cdk-step>

                        <cdk-step [optional]="true">
                            <ng-template cdkStepLabel>
                                <span class="number">2</span>
                                <span>Manual Bills</span>
                            </ng-template>
                            @if(cdkStepper.selectedIndex === 1){
                            <section class="">
                                <ng-template #template let-anchor>
                                    <span>{{ anchor.nativeElement.innerText }}</span>
                                </ng-template>

                                <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                    (mouseover)="showTooltip($event)">
                                    <kendo-grid [data]="manualBilsgridData" [reorderable]="true" filterable="menu"
                                        [columnMenu]="{ filter: true }" [resizable]="true" [pageable]="{
                            buttonCount: buttonCount,
                            info: info,
                            type: type,
                            pageSizes: manulaBillspageSizes,
                            previousNext: manulaBillspreviousNext
                          }" [pageSize]="manualpageSize" [sortable]="true" [selectable]="selectableSettingsManual"
                                        [filter]="manualbill.filter" [skip]="skip" [sort]="manualbill.sort"
                                        [columnMenu]="{ filter: true }" (filterChange)="onmanualFilterChange($event)"
                                        (sortChange)="onManualSortChange($event)" [resizable]="true"
                                        (pageChange)="manualconsumerPageChange($event)"
                                        (dataStateChange)="manualbillStateChange($event)"
                                        (selectionChange)="manualbillsonSelect($event)">

                                        <ng-template kendoGridToolbarTemplate>
                                            <!-- <kendo-textbox [style.width.px]="250" [style.height.px]="35"
                                                placeholder="Search in all columns..." [(ngModel)]="manualsarchedValue"
                                                (valueChange)="manualbilsonFilter($event)"></kendo-textbox>
                                            <kendo-grid-spacer></kendo-grid-spacer> -->
                                            <div class="search_icon">
                                                <kendo-textbox [style.width.px]="250" [style.height.px]="35"
                                                    [(ngModel)]="manualsarchedValue" [clearButton]="true"
                                                    (valueChange)="manualValueChange($event)"
                                                    (keydown.enter)="onSearchManualBills()"
                                                    placeholder="Search in all columns...">
                                                </kendo-textbox>

                                                <button class="btn btn-primary btn-md border-left-none"
                                                    (click)="manualbilsonFilter($event)">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div>
                                            <kendo-grid-spacer></kendo-grid-spacer>
                                            <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                (click)="createBill('manual')"><span class="fas fa-plus"> </span> &nbsp;
                                                Create Bill </button>


                                            <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                (click)="onManualExport('Excel')">
                                                <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                            </button>


                                            <!-- <button kendoGridPDFCommand class="btn btn-light" (click)="onExport()">
                                            <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->
                                        </ng-template>


                                        <!-- Pdf   start-->
                                        <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                            <kendo-grid
                                                [kendoGridBinding]="manualbillselectedRowdata.length>0 ? manualbillselectedRowdata:manualBilsgridData.data"
                                                #manualpdfGrid>
                                                <kendo-grid-column field="billNo" title="Bill No"></kendo-grid-column>
                                                <kendo-grid-column field="residentialUnit"
                                                    title="Residential Unit"></kendo-grid-column>
                                                <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                                <kendo-grid-column field="macAddress" title="Meter No">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="billAmount" title="Bill Amount">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="modeOfPayment" title="Mode of Payment">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="paidDate" title="Payment Date" filter="date">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="billGeneratedDate"
                                                    title="Bill Generated Date">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="transactionId" title="Payment Id">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="billMonth"
                                                    title="Bill Month"></kendo-grid-column>
                                                <kendo-grid-column field="paymentStatus" title="Status">
                                                </kendo-grid-column>
                                                <kendo-grid-excel fileName="manualBills.xlsx"></kendo-grid-excel>
                                                <kendo-grid-pdf fileName="manualBills.pdf" [allPages]="true"
                                                    paperSize="A4" [landscape]="true"></kendo-grid-pdf>
                                            </kendo-grid>
                                        </div>
                                        <!-- Pdf Edrt-->

                                        <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                            class="no-tooltip" [width]="40"></kendo-grid-checkbox-column>
                                        <kendo-grid-column field="billNo" title="Bill No"></kendo-grid-column>
                                        <kendo-grid-column field="residentialUnit"
                                            title="Residential Unit"></kendo-grid-column>
                                        <kendo-grid-column field="consumerName" title="Consumer"></kendo-grid-column>
                                        <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                        <kendo-grid-column field="macAddress" title="Meter No"
                                            [hidden]="true"></kendo-grid-column>
                                        <kendo-grid-column field="billAmount" title="Bill Amount">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{ dataItem.billAmount | currency: 'INR':'symbol':'1.2-2' }}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <!-- <kendo-grid-column field="billAmount" title="Bill Amount"></kendo-grid-column> -->
                                        <kendo-grid-column field="modeOfPayment" [hidden]="true"
                                            title="Mode of Payment"></kendo-grid-column>
                                        <kendo-grid-column field="paidDate" title="Payment Date" filter="date">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.paidDate =='-' ?'':
                                                dataItem.paidDate | date: 'dd MMM yyyy hh:mm a'}}

                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="billGeneratedDate" title="Bill Generated Date"
                                            filter="date" [hidden]="isbillmanualGeneratefields">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.billGeneratedDate =='' ?'-':
                                                dataItem.billGeneratedDate |
                                                date: 'dd MMM yyyy hh:mm a'}}

                                            </ng-template>
                                        </kendo-grid-column>


                                        <kendo-grid-column field="billMonth" title="Bill Month"></kendo-grid-column>
                                        <kendo-grid-column field="transactionId" title="Payment Id"
                                            [hidden]="true"></kendo-grid-column>
                                        <kendo-grid-column field="paymentStatus" title="Status">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <ng-container
                                                    *ngIf="dataItem.paymentStatus !== undefined; else noStatus">

                                                    <ng-container
                                                        *ngIf="dataItem.paymentStatus === 'Paid'; else unpaidOrRefund">
                                                        <button type="button"
                                                            class="btn btn-soft-success waves-effect waves-light">Paid</button>
                                                    </ng-container>

                                                    <ng-template #unpaidOrRefund>
                                                        <ng-container
                                                            *ngIf="dataItem.paymentStatus === 'Unpaid'; else inProgressOrCancelled">
                                                            <button type="button"
                                                                class="btn btn-soft-danger waves-effect waves-light">Unpaid</button>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-template #inProgressOrCancelled>
                                                        <ng-container
                                                            *ngIf="dataItem.paymentStatus === 'In Progress'; else cancelled">
                                                            <button type="button"
                                                                class="btn btn-soft-warning waves-effect waves-light">In
                                                                Progress</button>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-template #cancelled>
                                                        <ng-container
                                                            *ngIf="dataItem.paymentStatus === 'Cancelled'; else refund">
                                                            <button type="button"
                                                                class="btn btn-soft-danger waves-effect waves-light">Cancelled</button>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-template #refund>
                                                        <button type="button"
                                                            class="btn btn-soft-success waves-effect waves-light">
                                                            {{ dataItem.paymentStatus }}
                                                        </button>
                                                    </ng-template>

                                                </ng-container>

                                                <ng-template #noStatus>
                                                    <!-- Optional: Show something when `paymentStatus` is undefined -->
                                                    <span>No status</span>
                                                </ng-template>
                                            </ng-template>


                                            <!-- <ng-template kendoGridCellTemplate let-dataItem>
                                                @if(dataItem.paymentStatus !==undefined){

                                                @if(dataItem.paymentStatus=='paid'){ <button type="button"
                                                    class="btn btn-soft-success waves-effect waves-light">Paid</button>}
                                                @if(dataItem.paymentStatus=='Unpaid'){ <button type="button"
                                                    class="btn btn-soft-danger  waves-effect waves-light">Unpaid</button>}

                                                @if(dataItem.paymentStatus=='Refund'){ <button type="button"
                                                    class="btn btn-soft-success waves-effect waves-light">Refund</button>}

                                                }

                                            </ng-template> -->
                                        </kendo-grid-column>

                                        <kendo-grid-command-column title="" [columnMenu]="false" [width]="100">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <div class="d-flex">
                                                    <span kendoTooltip title="Go to Info" class="me-2"
                                                        (click)="viewBilsdeatils(dataItem)" style="cursor: pointer; ">
                                                        <i class="fas fa-info-circle text-success"></i>
                                                    </span>
                                                    <span kendoTooltip title="Download Bill" class="ms-2"
                                                        (click)="downloadbillsumary(dataItem)"
                                                        style="cursor: pointer; ">
                                                        <i class="fa fa-download text-success" aria-hidden="true"></i>
                                                    </span>
                                                    <span kendoTooltip title="Download receipt"
                                                        (click)="downloadBillsPaymentrecceipt(dataItem)"
                                                        *ngIf="dataItem.paymentStatus.toLowerCase() === 'paid'"
                                                        class="ms-2" style="cursor: pointer; ">
                                                        <i class="fa fa-download text-success" aria-hidden="true"></i>
                                                    </span>

                                                </div>

                                            </ng-template>

                                        </kendo-grid-command-column>

                                    </kendo-grid>
                                </div>
                            </section>
                            }
                        </cdk-step>
                        <cdk-step [optional]="true">
                            <ng-template cdkStepLabel>
                                <span class="number">3</span>
                                <span>Adhoc Bills</span>
                            </ng-template>
                            @if(cdkStepper.selectedIndex === 2){
                            <section class="">
                                <ng-template #template let-anchor>
                                    <span>{{ anchor.nativeElement.innerText }}</span>
                                </ng-template>

                                <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                    (mouseover)="showTooltip($event)">
                                    <kendo-grid [data]="adhocBillsgridData" [reorderable]="true" filterable="menu"
                                        [columnMenu]="{ filter: true }" [resizable]="true" [pageable]="{
                                buttonCount: buttonCount,
                                info: info,
                                type: type,
                                pageSizes: adhocbillpageSizes,
                                previousNext: adhocbillpreviousNext
                              }" [pageSize]="adhocpageSize" [sortable]="true" [selectable]="selectableSettingsAdhoc"
                                        [filter]="adhocbill.filter" [skip]="skip" [sort]="adhocbill.sort"
                                        [columnMenu]="{ filter: true }" (filterChange)="onadhocFilterChange($event)"
                                        (sortChange)="onAdhocSortChange($event)" [resizable]="true"
                                        (pageChange)="adhocPageChange($event)"
                                        (dataStateChange)="adhocbillStateChange($event)"
                                        (selectionChange)="adhocbillsonSelect($event)">
                                        <ng-template kendoGridToolbarTemplate>

                                            <div class="search_icon">
                                                <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                                    (valueChange)="adhocValueChange($event)" [style.height.px]="35"
                                                    [(ngModel)]="adhocSearchedValue"
                                                    (keydown.enter)="onSearchAdhoclBills()"
                                                    placeholder="Search in all columns...">
                                                </kendo-textbox>

                                                <button class="btn btn-primary btn-md border-left-none"
                                                    (click)="adhocbilsonFilter($event)">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div>
                                            <kendo-grid-spacer></kendo-grid-spacer>

                                            <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                (click)="createBill('adhoc')"><span class="fas fa-plus"> </span> &nbsp;
                                                Create Bill </button>


                                            <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                (click)="onExport('Excel')">
                                                <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                            </button>




                                        </ng-template>

                                        <!-- Pdf   start-->
                                        <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                            <kendo-grid
                                                [kendoGridBinding]="adhocbillselectedRowdata.length>0 ? adhocbillselectedRowdata:adhocBillsgridData.data"
                                                #pdfGrid>
                                                <kendo-grid-column field="billNo" title="Bill No"></kendo-grid-column>
                                                <kendo-grid-column field="residentialUnit"
                                                    title="Residential Unit"></kendo-grid-column>
                                                <kendo-grid-column field="consumerName" title="Consumer Name">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                                <kendo-grid-column field="macAddress" title="Meter No">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="billAmount" title="Bill Amount">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.billAmount | currency: 'INR':'symbol':'1.2-2' }}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <!-- <kendo-grid-column field="billAmount" title="Bill Amount">
                                            </kendo-grid-column> -->
                                                <kendo-grid-column field="modeOfPayment" title="Mode of Payment">
                                                </kendo-grid-column>

                                                <kendo-grid-column field="paymentDate" title="Payment Date"
                                                    filter="date">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="billGeneratedDate" title="Bill Generated Date"
                                                    filter="date">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="consumptioDate" title="Consumption b/w"
                                                    filter="date">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="transactionId" title="Payment Id">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="paymentStatus" title="Status">
                                                </kendo-grid-column>

                                                <kendo-grid-excel fileName="adhocBills.xlsx"></kendo-grid-excel>
                                                <kendo-grid-pdf fileName="adhocBills.pdf" [allPages]="true"
                                                    paperSize="A4" [landscape]="true"></kendo-grid-pdf>
                                            </kendo-grid>
                                        </div>
                                        <!-- Pdf Edrt-->

                                        <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                            class="no-tooltip" [width]="40"></kendo-grid-checkbox-column>
                                        <kendo-grid-column field="billNo" title="Bill Number"></kendo-grid-column>
                                        <kendo-grid-column field="residentialUnit"
                                            title="Residential Unit"></kendo-grid-column>
                                        <kendo-grid-column field="consumerName" title="Consumer"></kendo-grid-column>
                                        <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                        <kendo-grid-column field="macAddress" title="Meter No"
                                            [hidden]="true"></kendo-grid-column>
                                        <kendo-grid-column field="billAmount" title="Bill Amount"></kendo-grid-column>
                                        <kendo-grid-column field="modeOfPayment" [hidden]="true"
                                            title="Mode of Payment"></kendo-grid-column>
                                        <kendo-grid-column field="paidDate" title="Payment Date" filter="date">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.paidDate =='-' ?'-':
                                                dataItem.paidDate | date: 'dd MMM yyyy hh:mm a'}}

                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="billGeneratedDate"
                                            [hidden]="isbillAdhocGeneratefields" title="Bill Generated Date"
                                            filter="date">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.billGeneratedDate =='' ?'-':
                                                dataItem.billGeneratedDate | date: 'dd MMM yyyy hh:mm a'}}

                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="billGeneratedFromDate" [hidden]="true"
                                            title="Consumption b/w" filter="date">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.billGeneratedFromDate =='' ?'':
                                                dataItem.billGeneratedFromDate | date: 'dd MMM yyyy'}} -
                                                {{dataItem.billGeneratedToDate =='' ?'':
                                                dataItem.billGeneratedToDate | date: 'dd MMM yyyy'}}

                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="transactionId" title="Payment Id"
                                            [hidden]="true"></kendo-grid-column>
                                        <kendo-grid-column field="paymentStatus" title="Status">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <ng-container
                                                    *ngIf="dataItem.paymentStatus !== undefined; else noStatus">

                                                    <ng-container
                                                        *ngIf="dataItem.paymentStatus === 'Paid'; else unpaidOrRefund">
                                                        <button type="button"
                                                            class="btn btn-soft-success waves-effect waves-light">Paid</button>
                                                    </ng-container>

                                                    <ng-template #unpaidOrRefund>
                                                        <ng-container
                                                            *ngIf="dataItem.paymentStatus === 'Unpaid'; else inProgressOrCancelled">
                                                            <button type="button"
                                                                class="btn btn-soft-danger waves-effect waves-light">Unpaid</button>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-template #inProgressOrCancelled>
                                                        <ng-container
                                                            *ngIf="dataItem.paymentStatus === 'In Progress'; else cancelled">
                                                            <button type="button"
                                                                class="btn btn-soft-warning waves-effect waves-light">In
                                                                Progress</button>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-template #cancelled>
                                                        <ng-container
                                                            *ngIf="dataItem.paymentStatus === 'Cancelled'; else refund">
                                                            <button type="button"
                                                                class="btn btn-soft-danger waves-effect waves-light">Cancelled</button>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-template #refund>
                                                        <button type="button"
                                                            class="btn btn-soft-success waves-effect waves-light">
                                                            {{ dataItem.paymentStatus }}
                                                        </button>
                                                    </ng-template>

                                                </ng-container>

                                                <ng-template #noStatus>
                                                    <!-- Optional: Show something when `paymentStatus` is undefined -->
                                                    <span>No status</span>
                                                </ng-template>
                                            </ng-template>



                                        </kendo-grid-column>

                                        <kendo-grid-command-column title="" [columnMenu]="false" [width]="100">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <div class="d-flex">
                                                    <span kendoTooltip title="Go to Info" class="me-2"
                                                        (click)="viewBilsdeatils(dataItem)" style="cursor: pointer; ">
                                                        <i class="fas fa-info-circle text-success"></i>
                                                    </span>
                                                    <!-- <span kendoTooltip title="Download Bill" class="ms-2"
                                                        (click)="onCheckboxClick(dataItem)" style="cursor: pointer; ">
                                                        <i class="fa fa-download text-success" aria-hidden="true"></i>
                                                    </span> -->

                                                    <span kendoTooltip title="Download Bill" class="ms-2"
                                                        (click)="downloadbillsumary(dataItem)"
                                                        style="cursor: pointer; ">
                                                        <i class="fa fa-download text-success" aria-hidden="true"></i>
                                                    </span>
                                                    <span kendoTooltip title="Download receipt"
                                                        (click)="downloadBillsPaymentrecceipt(dataItem)"
                                                        *ngIf="dataItem.paymentStatus.toLowerCase() === 'paid'"
                                                        class="ms-2" style="cursor: pointer; ">
                                                        <i class="fa fa-download text-success" aria-hidden="true"></i>
                                                    </span>

                                                </div>

                                            </ng-template>
                                            <!--  <ng-template kendoGridCellTemplate let-dataItem>
                                                <button class="btn btn-success btn-sm d-flex align-items-center "
                                                    tooltip="Go to Project Details" (click)="viewBilsdeatils(dataItem)">
                                                    <i class="fas fa-info-circle"></i>
                                                </button>
                                               
                                            </ng-template> -->
                                        </kendo-grid-command-column>

                                    </kendo-grid>
                                </div>
                            </section>
                            }
                        </cdk-step>

                    </ng-stepper>

                </div>
            </div>
        </div>
    </div>
</div>


<!-- Invalid Bill modal -->
<div bsModal #invalidBillModal="bs-modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header ">
                <h5 class="modal-title">Invalid Bill</h5>
                <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
                    (click)="invalidBillModal?.hide()"></button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal">
                    <div class="mb-3">
                        <label> Reason for invalid bill</label>
                        <textarea [placeholder]="'Enter your reason here'" class="form-control" rows="5"></textarea>

                    </div>

                </form>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
                <button type="button" class="btn btn-primary waves-effect waves-light">Submit</button>
            </div>
        </div><!-- /.modal -->
    </div>
</div>
<!-- Invalid Bill modal End  -->