import { Component } from '@angular/core';

import { saveAs } from 'file-saver';

import * as XLSX from 'xlsx';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import { getAllWalletHistorylist } from '../../../store/Wallet/wallet.action';
import { getAllWalletHistoryResponse } from '../../../store/Wallet/wallet-selector';
import { getBillHistoryExport } from '../../../store/customer/consumer.action';
import { Subscription } from 'rxjs';
import { getBillHistoryExportResponse } from '../../../store/customer/consumer-selector';
import { SweetAlertIcon } from '../../../shared/constants/enum';
@Component({
  selector: 'app-rp-wallet-history',
  templateUrl: './rp-wallet-history.component.html',
  styleUrl: './rp-wallet-history.component.scss',
})
export class RpWalletHistoryComponent {
  currentUserData: any;
  walletId: any;
  totalwallethistoryCount: number = 0;
  getAllWalletHistoryList: any;
  billingType: any;

  breadCrumbItems: Array<{}> | undefined;
  private exportSubscription: Subscription = new Subscription();
  constructor(private sharedService: SharedService, private store: Store) {
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');

    if (this.currentUserData.currentUserRoleDetails !== null) {
      this.walletId =
        this.currentUserData?.currentUserRoleDetails?.consumerWalletId;
      console.log('Wallet ID:', this.walletId);
      this.sharedService.setwalletHistoryById(this.walletId);
    }
    console.log(this.currentUserData)
    this.billingType = this.currentUserData?.project?.billingType;
    this.breadCrumbItems = [{ label: 'Wallet History' }];
    this.exportSubscription = this.store.pipe(select(getAllWalletHistoryResponse)).subscribe
      ((data) => {
        if (data) {
          if (data.items.length > 0) {
            this.totalwallethistoryCount = data.totalCount;
            this.getAllWalletHistoryList = [...data.items];
            this.billstypeExport(this.getAllWalletHistoryList);
          } else {
            this.sharedService.showMessageDialog(
              'No data to display',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });


  }
  public onCountChange(count: number): void {
    this.totalwallethistoryCount = count;
    console.log('Count received from child:', this.totalwallethistoryCount);
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  walletsExportAll() {
    this.getAllWalletHistory();
  }

  getAllWalletHistory(): void {
    this.store.dispatch(
      getAllWalletHistorylist({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
        walletId: this.walletId,
      })
    );
  }

  billstypeExport(response: any) {
    if (this.billingType == 'Postpaid') {
      this.billHistoryExport(response);

    } else {
      this.export(response);
    }
  }
  export(data: any): void {
    const workbook = XLSX.utils.book_new();
    const masterDetailData: any[] = [];
    //console.log(data)
    data.forEach(
      (item: any) => {
        masterDetailData.push({
          'Date': item.paidDate,
          'Amount Deducted': item.totalAmountDeducted,
          "Opening Balance": item.openingBalance,
          "Closeing Balance": item.closingBalance,
          // 'Residential Unit': '',
          'utility': '',
          'Device Sr No': '',
          'Mac Address': '',
          'consumption': '',
          'Consumption Unit EB': '',
          'Consumption Unit DG': '',
          'Unit Rate': '',
          //'Unit Rate EB': '',
          // 'Unit Rate DG': '',
          'Available Balance': '',
          'Initial Reading': '',
          'Final Reading': '',
          'Initial MeterReading EB': '',
          'Initial Meter Reading DG': '',
          'Final MeterReading EB': '',
          'Final Meter Reading DG': '',
          'Service Name': '',
          'Gst Amount': '',
          'Gst percentage': '',
        });

        item.walletDtls.forEach(
          (detail: any) => {
            masterDetailData.push({
              Date: '',
              amountDeducted: '',
              // 'Residential Unit': detail.residentialUnit,
              'utility': detail.utility,
              'Device Sr No': detail.meterNo,
              'Mac Address': detail.macAddress,
              'consumption': detail.consumptionUnits,
              'Consumption Unit EB': detail.consumptionUnitsEB,
              'Consumption Unit DG': detail.consumptionUnitsDG,
              'Amount Deducted': detail.amountDeducted,
              'Available Balance': detail.availableBalance,
              'Unit Rate': detail.unitRate,
              //'Unit Rate EB': detail.unitRateEB,
              // 'Unit Rate DG': detail.unitRateDG,
              'Initial Reading': detail.initialMeterReading,
              'Final Reading': detail.finalMeterReading,
              'InitialMeter Reading EB': detail.initialMeterReadingEB,
              'Initial Meter Reading DG': detail.initialMeterReadingDG,
              'FinalMeter Reading EB': detail.finalMeterReadingEB,
              'FinalMeter Reading DG': detail.finalMeterReadingDG,
              'service Name': detail.serviceName,
              'Gst Amount': detail.gstAmount,
              'Gst Percentage': detail.gstPer,

            });
          }
        );
      }
    );

    const worksheet = XLSX.utils.json_to_sheet(masterDetailData, {

    });

    const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })];
      if (cell) {
        cell.s = { font: { bold: true } };
      }
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, 'WalletHistory');
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'WalletHistory.xlsx'
    );
  }




  // bill History export Excl:
  billHistoryExport(data: any): void {
    const workbook = XLSX.utils.book_new();
    const masterDetailData: any[] = [];

    data.forEach(
      (item: any) => {
        masterDetailData.push({
          'Paid Date': item.paidDate,
          'Amount Deducted': item.amountDeducted,
          'Residential Unit': '',
          'Bill No': '',
          'utility': '',
          'Meter No': '',
          'Bill Date': '',
          'Bill Amount': '',
          'payment Status': '',
          'Mode Of Payment': '',

        });

        item.walletDtls.forEach(
          (detail: any) => {
            masterDetailData.push({
              'Paid Date': '',
              'Amount Deducted': '',
              'Residential Unit': detail.residentialUnit,
              'Bill No': detail.billNo,
              'utility': detail.utility,
              'Meter No': detail.meterNo,
              'Bill Date': detail.billDate,
              'Bill Aamount': detail.amount,
              'Payment Status': detail.paymentStatus,
              'Mode Of Payment': detail.modeOfPayment,

            });
          }
        );
      }
    );

    const worksheet = XLSX.utils.json_to_sheet(masterDetailData, {

    });

    const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })];
      if (cell) {
        cell.s = { font: { bold: true } };
      }
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, 'WalletHistory');
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'WalletHistory.xlsx'
    );
  }
}
