import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { url } from 'inspector';

@Injectable({
  providedIn: 'root'
})
export class BillsService {

  constructor(private http: HttpClient, private router: Router) { }


  getBillsUrl(pageIndex: number = 1, pageSize: number | undefined, searchTerm: string = '', id: string, billType: string, filter?: string,
    orderBy?: string): Observable<any> {
    /*  let url;
     if (searchTerm) {
       url = `${AppConfigData.getBillsUrl + id}?billType=${billType}&PageIndex=${pageIndex}&PageSize=${pageSize}&search=${searchTerm}`;
     } else {
       url = `${AppConfigData.getBillsUrl + id}?billType=${billType}&PageIndex=${pageIndex}&PageSize=${pageSize}`;
     } */

    let url =
      `${AppConfigData.getBillsUrl + id}?billType=${billType}&PageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  getResidentialUrl(projectId: string): Observable<any> {
    let url;
    url = `${AppConfigData.getResidentialUnitsUrl + projectId}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getBillReadingsInfoUrl(id: string, projectId: string, MacAddress: string): Observable<any> {
    let url;
    url = `${AppConfigData.getBillReadingsInfoUrl + projectId + '/' + id}?MacAddress=${MacAddress}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getBillDeatilsByMonthUrl(id: string, projectId: string, BillMonth: string, MacAddress: string, IsCombined: boolean): Observable<any> {
    let url;
    console.log(id, projectId, BillMonth);
    url = `${AppConfigData.getBillReadingsByMonthUrl + projectId + '/' + id}?BillMonth=${BillMonth}&MacAddress=${MacAddress}&IsCombined=${IsCombined}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getAdhocBillDeatilsByMonthUrl(request: any): Observable<any> {
    let url;
    url = `${AppConfigData.getAdhocBillReadingsByMonthUrl}`;
    return this.http.post<any>(url, request).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }



  getManualUrl(pageIndex: number = 1, pageSize: number | undefined, searchTerm: string = '', id: string, billType: string, filter?: string,
    orderBy?: string): Observable<any> {
    /*  let url;
     if (searchTerm) {
       url = `${AppConfigData.getManualBillUrl + id}?billType=${billType}&PageIndex=${pageIndex}&PageSize=${pageSize}&search=${searchTerm}`;
     } else {
       url = `${AppConfigData.getManualBillUrl + id}?billType=${billType}&PageIndex=${pageIndex}&PageSize=${pageSize}`;
     } */
    let url =
      `${AppConfigData.getManualBillUrl + id}?billType=${billType}&PageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  getAdhocBillsUrl(projectId: string, pageIndex: number = 1, pageSize: number = 10, searchTerm: string = '', billType: string, filter?: string,
    orderBy?: string): Observable<any> {
    /*  let url;
     if (searchTerm) {
       url = `${AppConfigData.getAdhocBillsUrl + id}?billType=${billType}&PageIndex=${pageIndex}&PageSize=${pageSize}&search=${searchTerm}`;
     } else {
       url = `${AppConfigData.getAdhocBillsUrl + id}?billType=${billType}&PageIndex=${pageIndex}&PageSize=${pageSize}`;
     } */

    let url =
      `${AppConfigData.getAdhocBillsUrl + projectId}?billType=${billType}&PageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getBillsById(id: any): Observable<any> {
    //  let url = `${AppConfigData.getBillsByIdUrl + id}?billType=${billType}`
    return this.http.get<any>(AppConfigData.getBillsByIdUrl + id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  creatiInvalidBills(paylod: any): Observable<any> {

    let url = `${AppConfigData.updateInvalidBillUrl}`
    return this.http.post<any>(url, paylod,)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('update Invalid Bill failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  createBill(bills: any): Observable<any> {
    // console.log('userData->', bills)

    return this.http.post<any>(AppConfigData.createBillUrl, bills).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  paycashbill(bills: any): Observable<any> {
    // console.log('userData->', bills)

    return this.http.post<any>(AppConfigData.paycashBillUrl, bills).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }


  //bills Invoice 

  getbillsInvoice(projectId: string = '',
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string,
    IsConsumer?: boolean
  ): Observable<any> {


    let url =
      `${AppConfigData.getInvoicehistory + projectId}/${IsConsumer}?PageIndex=${pageIndex}&PageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }



  getInvoiceExport(projectId: any, criteria: any, IsConsumer: boolean): Observable<any> {
    let url;
    url = `${AppConfigData.getInvoicehistory + projectId + '/' + IsConsumer
      }?criteria=${criteria}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }


  cretaeTransfer(payload: any): Observable<any> {
    let url;
    url = `${AppConfigData.paymentCreateTransferUrl}`

    return this.http.post<any>(url, payload).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }


  releaseBills(payload: any): Observable<any> {
    let url;
    url = `${AppConfigData.releaseBillsUrl}`

    return this.http.post<any>(url, payload).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }









}
