import { Component, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../shared/shared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';

import {
  process,
  State,
  toODataString,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { PdfexportService } from '../../core/services/pdfexport.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { table_pageSize } from '../../shared/constants/templatesNames';
import { SweetAlertIcon } from '../../shared/constants/enum';
import {
  Getmapping,
  GetAllmapping,
  mappingUmdSuccess,
  resetState,
} from '../../store/mapping/mapping.actions';
import {
  unMappingUmdSuccessResponse,
  mappingUmdSuccessResponse,
} from '../../store/mapping/mapping-selector';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RpAddMappingComponent } from './rp-app-Mapping/rp-app-mapping.component';
import { unMappingUmd } from '../../store/mapping/mapping.actions';
import {
  getmappingConfigResponse,
  getAllmappingConfigResponse,
} from '../../store/mapping/mapping-selector';
import Swal from 'sweetalert2';
import { ConfirmationModal } from '../../shared/modals/common';
@Component({
  selector: 'app-rp-mapping',
  templateUrl: './rp-mapping.component.html',
  styleUrl: './rp-mapping.component.scss',
})
export class RpMappingComponent {
  // public filterMode: FilterableSettings = 'row';
  // public filePdfIcon: SVGIcon = filePdfIcon;
  // public fileExcelIcon: SVGIcon = fileExcelIcon;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  // @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    checkboxOnly: true,
  };
  breadCrumbItems: Array<{}> | undefined;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  selectValue!: string[];
  public mappingState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  currentUser: any;
  projectId: any;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public pageSize = 10;
  public skip = 0;
  totalmappingCount: number = 0;
  currentPage: number = 1;
  mappingconfigList: any[] = [];
  public sort: SortDescriptor[] = [];
  public mappinggridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  //public pageSizes = true;
  public previousNext = true;
  mySelectionmapping: any = [];
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  allmappingconfigList: any[] = [];
  monitoringData: any;
  constructor(
    private modalService: BsModalService,
    private store: Store,
    private sharedService: SharedService,
    private router: Router,
    private PdfexportService: PdfexportService
  ) {
    if (history.state.monitoringData) {
      this.monitoringData = JSON.parse(history.state.monitoringData);
    }
    this.getStoreInfo();
    this.breadCrumbItems = [
      {
        label: 'Project Details',
        url: 'projectDetails',
        active: true,
      },
      {
        label: 'Linked Meters',
      },
    ];
  }
  private exportSubscription: Subscription = new Subscription();
  getStoreInfo() {


    let mappingsConfiguration$ = this.store.pipe(
      select(getmappingConfigResponse)
    );
    this.exportSubscription = this.store
      .pipe(select(getAllmappingConfigResponse))
      .subscribe((res: any) => {
        if (res) {
          this.allmappingconfigList = [];
          if (res.items.length > 0) {
            this.allmappingconfigList = [...res.items];
            const transformedData = this.allmappingconfigList.map(
              (item: any) => ({
                utility: item.utility.name,
                macAddress: item.macAddress,
                'Meter Serial No': item.meterSerialNo,
                'Mcb Main Power': item.mcbMainPower,
                Status: item.status,
              })
            );
            this.PdfexportService.downloadExcel(
              transformedData,
              'MappingReports'
            );
          } else {
            this.sharedService.showMessageDialog(
              'No data to display',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }

      });

    let unMappingUmdSuccessResponse$ = this.store.pipe(
      select(unMappingUmdSuccessResponse)
    );
    let mappingUmdSuccess$ = this.store.pipe(select(mappingUmdSuccessResponse));
    mappingUmdSuccess$.subscribe((res: any) => {
      if (res && res.statusCode == 200) {
        this.getAllmappingConfigurations();
      }
      // this.loadMterConfig();
      console.log('mappingsConfiguration', res);
    });
    mappingsConfiguration$.subscribe((res: any) => {
      if (res) {
        this.mappingconfigList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalmappingCount = res.totalCount;
      }
      this.loadMterConfig();
      console.log('mappingsConfiguration', res);
    });

    unMappingUmdSuccessResponse$.subscribe((res: any) => {
      if (res) {
        this.getAllmappingConfigurations();
        console.log('mappingsConfiguration', res);
      }
      console.log('mappingsConfiguration', res);
    });
  }
  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };


  addNewMapping() {
    this.monitoringData = JSON.parse(history.state.monitoringData);
    let monitoringData = this.monitoringData;
    this.modalRef = this.modalService.show(RpAddMappingComponent, {
      initialState: { monitoringData },
      ...this.config,
    });
  }
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project?.id;
    this.getAllmappingConfigurations();
  }

  ngOnDestroy() {
    this.store.dispatch(resetState());
    // this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  unMapping(data: any) {
    let modalInfo: ConfirmationModal = {
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: SweetAlertIcon.WARNING,
      showCancelButton: true,
      confirmButtonText: 'Yes, Unlink it!',
      cancelButtonText: 'No, cancel!',
    };
    this.sharedService.showConfirmationDialog(modalInfo).then((result) => {
      console.log(result);
      this.monitoringData = JSON.parse(history.state.monitoringData);
      if (result.isConfirmed) {
        this.store.dispatch(
          unMappingUmd({
            id: this.monitoringData.id,
            Umdid: data.id,
          })
        );
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sharedService.showMessageDialog(
          'Cancelled',
          '',
          SweetAlertIcon.INFO
        );
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   // Handle cancellation
      // }
    });
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.mappingState.sort = sort;
    console.log(this.mappingState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.mappingState.sort
    );

    this.orderByQuery = orderByQuery;

    this.getAllmappingConfigurations();
  }

  public onprojectFilterChange(filter: CompositeFilterDescriptor): void {
    this.mappingState.filter = filter;
    console.log(this.mappingState);
    const queryString = this.sharedService.createQuery(this.mappingState);
    this.queryString = this.sharedService.createQuery(this.mappingState);
    this.getAllmappingConfigurations();
    console.log(queryString);
  }

  getAllmappingConfigurations(): void {
    this.monitoringData = JSON.parse(history.state.monitoringData);
    this.store.dispatch(
      Getmapping({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        id: this.monitoringData.id,
      })
    );
  }

  getTotalmappingConfigurations(): void {
    this.store.dispatch(
      GetAllmapping({
        pageIndex: 1,
        pageSize: this.totalmappingCount,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        id: this.monitoringData.id,
      })
    );
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.state.filter = filter;
    const queryString = this.createQuery(this.state);
    console.log(queryString);
  }

  private createQuery(state: State): string {
    // Implement this method to create a query string from the state object
    // This should convert the state to your API's query paramappings
    // Example implementation
    const queryStr = `${toODataString(state)}&$count=true`;
    console.log(queryStr);

    let query = `skip=${state.skip}&take=${state.take}`;

    if (state.filter) {
      const filters = state.filter.filters
        .map((f) => {
          const field = (f as any).field;
          const value = (f as any).value;
          return `${field}=${value}`;
        })
        .join('&');
      query += `&${filters}`;
    }

    return query;
  }

  protected mappingConfigPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.mySelectionmapping = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getAllmappingConfigurations();
    this.loadMterConfig();
  }

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionmapping.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionmapping.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.ismappingRowSelected(row.dataItem)) {
        this.mySelectionmapping.push(row.dataItem);
      }
    });

    console.log(this.mySelectionmapping);
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadMterConfig();
  }

  private loadMterConfig(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.mappingconfigList, state);

    this.mappinggridData = {
      data: processedData.data,
      total: this.totalmappingCount,
    };

    this.mappinggridData.data = this.mappinggridData.data.map((project) => {
      console.log(new Date(project.currentDate));
      return {
        ...project,
        currentDate: new Date(project.currentDate), // Modify the billDate property
      };
    });
  }

  public ismappingRowSelected = (rowArg: any) => {
    return this.mySelectionmapping.some((row: any) => row.id === rowArg.id);
  };
  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.mappingConfigPageChange({ skip: 0, take: this.pageSize })
      this.getAllmappingConfigurations()
    }
  }
  public onmappingConfigFilter(value: any): void {
    const inputValue = value;
    this.mappingConfigPageChange({ skip: 0, take: this.pageSize })
    this.getAllmappingConfigurations();
  }
  exportAll() {
    this.getTotalmappingConfigurations();
  }
  public onmappingPdfExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
}
