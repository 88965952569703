import { Wallet } from './../../../shared/components/wallet-history-expended/wallet-history-expended.component';
import { LoaderService } from './../../../core/services/loader.service';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  createMaster,
  updateMaster,
} from '../../../store/Masters/masters.actions';
import { Helper } from '../../../core/helpers/helper';
import { Validations } from '../../../shared/constants/validations';
import { Umd } from '../../../shared/modals/common';
import { GetUmd, createUmd, updateUmd } from '../../../store/Umd/umd.actions';
import { McbButtons, UtilityType, valveRelaystatus } from '../../../shared/constants/enum';
import { Utilites } from '../../../store/Utilities/utilities.models';
import { UtilitesService } from '../../../core/services/utilities.services';
@Component({
  selector: 'app-rp-create-umd',
  templateUrl: './rp-create-umd.component.html',
  styleUrl: './rp-create-umd.component.scss',
})
export class RpCreateUMDComponent {
  // configData: any;
  createUMDForm!: UntypedFormGroup;
  submitted: boolean = false;
  @Input() umdData!: any;
  @Input() configData!: any;

  Umd_Validations = Validations.UmdCreatePage;
  currentUser: any;
  projectId: any;
  gatewayList: any[] = []
  UtilityType = UtilityType;
  mcbPowerButtons = Object.values(McbButtons); // Convert enum to array of values
  valveRelaystatus = Object.values(valveRelaystatus); // Convert enum to array of values

  requiredfield: boolean = false;
  requiredSlabNamefield: boolean = false;
  requiredMcbMainPowerfield: boolean = false;
  waterTypeItems: any = [];
  gastyeItem: any[] = [];
  eletricityTypeItem: any[] = []
  constructor(
    private router: Router,
    private store: Store,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private utilitesService: UtilitesService
  ) { }
  dismissModal(): void {
    this.modalService.hide();
  }


  isDisplay(utility: any) {

    const matchingUtility = this.configData?.utilities.find(
      (u: any) => u.id === utility
    );
    return matchingUtility?.name;

  }


  CreateUMD(type: string): void {
    this.submitted = true;
    if (this.createUMDForm.valid) {
      this.loaderService.setLoading(true);
      let request = {
        "projectId": this.createUMDForm.get('projectId')?.value,
        "utilityMasterId": this.createUMDForm.get('utilityMasterId')?.value,
        "macAddress": this.createUMDForm.get('macAddress')?.value,
        "status": this.createUMDForm.get('status')?.value,
        "meterSerialNo": this.createUMDForm.get('meterSerialNo')?.value,
        "mcbMainPower": this.createUMDForm.get('mcbMainPower')?.value,
        "supplierId": this.createUMDForm.get('supplierId')?.value,
        "gatewayInformationId": this.createUMDForm.get('isWiredCommunicationDevice')?.value ? this.selectedGateway.id : null,
        "gatewayName": this.createUMDForm.get('isWiredCommunicationDevice')?.value ? this.selectedGateway.serialNumber : null,
        "isWiredCommunicationDevice": this.createUMDForm.get('isWiredCommunicationDevice')?.value,
        'deviceId': this.createUMDForm.get('isWiredCommunicationDevice')?.value ? this.createUMDForm.get('deviceId')?.value : ''

      }
      // Process form data (send to backend, etc.)
      // console.log(this.createUMDForm.value);
      if (type == 'Create') {

        this.store.dispatch(createUmd({ UmdData: request }));
      } else if (type == 'Update') {
        console.log(this.createUMDForm.get('valveRelaystatus')?.value)
        let request = {
          "projectId": this.createUMDForm.get('projectId')?.value,
          "utilityMasterId": this.createUMDForm.get('utilityMasterId')?.value,
          "macAddress": this.createUMDForm.get('macAddress')?.value,
          "status": this.createUMDForm.get('status')?.value,
          "meterSerialNo": this.createUMDForm.get('meterSerialNo')?.value,
          "mcbMainPower": this.createUMDForm.get('mcbMainPower')?.value,
          "supplierId": this.createUMDForm.get('supplierId')?.value,
          "gatewayInformationId": this.createUMDForm.get('isWiredCommunicationDevice')?.value ? this.selectedGateway.id : null,
          "gatewayName": this.createUMDForm.get('isWiredCommunicationDevice')?.value ? this.selectedGateway.serialNumber : null,
          "isWiredCommunicationDevice": this.createUMDForm.get('isWiredCommunicationDevice')?.value,
          'valveRelaystatus': this.createUMDForm.get('valveRelaystatus')?.value == 'On' ? true : false,
          'deviceId': this.createUMDForm.get('isWiredCommunicationDevice')?.value ? this.createUMDForm.get('deviceId')?.value : ''
        }

        // console.log(this.createUMDForm.value);
        this.store.dispatch(
          updateUmd({
            UmdData: request,
            id: this.umdData.id ? this.umdData.id : '',
          })
        );
      }
      this.modalService.hide();
    }
  }

  get f() {
    return this.createUMDForm.controls;
  }
  regex = /^[a-zA-Z0-9-]*$/;
  onlynumber = /^\d+$/

  ngOnInit(): void {
    // this.createUMDForm?.get('utilityMasterId')?.valueChanges.subscribe((value) => {
    //   console.log(value);

    // this.createUMDForm.get('billDate')?.clearValidators();
    // this.createUMDForm.get('billDate')?.updateValueAndValidity();

    // this.createUMDForm
    // .get('RelayCutofflimit')
    // ?.setValidators([
    //   Validators.required,
    //   Helper.numberWithDecimalsValidator(),
    // ]);
    // });
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;

    this.createUMDForm = this.formBuilder.group({
      projectId: this.projectId,
      utilityMasterId: [, Validators.required],
      macAddress: ['', [Validators.required, Validators.pattern(this.regex)]],
      meterSerialNo: ['', [Validators.required, Validators.pattern(this.regex)]],
      status: [, Validators.required],
      mcbMainPower: [,],
      gatewayName: [],
      deviceId: [],
      valveRelaystatus: [],
      isWiredCommunicationDevice: [false],
      supplierId: [null,]
    });

    this.createUMDForm?.get('utilityMasterId')?.valueChanges.subscribe((value) => {
      console.log(value);
      this.handleUtilityChange(value);
    });

    /*   this.waterTypeItems = this.configData?.suppliers.filter(
        (item: any) => item.master.name.toLowerCase() === 'water'
      ); */




    // this.getAllConfigData();
    this.patchForm();

    this.utilitesService.getGeatewayList(this.projectId).subscribe({
      next: (response) => {
        console.log(response)
        if (response && response.statusCode === 200) {
          this.gatewayList = response.data;
          /*  response.data.forEach((item: any) => {
             this.getwayList.push({ id: item.id, name: item.serialNumber })
           }) */
          console.log(this.gatewayList)

        }


      }, error: (err) => {

      },
    })
  }

  handleUtilityChange(value: any): void {
    if (value) {
      this.requiredfield = false;
      this.requiredSlabNamefield = false;
      this.requiredMcbMainPowerfield = false;
      if (this.isDisplay(value) === UtilityType.ELECTRICITY) {
        this.requiredfield = true;
        this.requiredMcbMainPowerfield = true;
        this.createUMDForm.get('mcbMainPower')?.setValidators([Validators.required]);
      } else {
        this.createUMDForm.get('mcbMainPower')?.clearValidators();
      }

      if (this.isDisplay(value) === UtilityType.WATER) {
        this.requiredSlabNamefield = true;
        this.createUMDForm.get('supplierId')?.setValidators([Validators.required]);
      } else {
        this.createUMDForm.get('supplierId')?.clearValidators();

      }
      this.createUMDForm.get('supplierId')?.updateValueAndValidity();
      this.createUMDForm.get('mcbMainPower')?.updateValueAndValidity(); // Ensure the state is updated
    }
  }
  iswirdeCommunication: boolean = false
  onCheckboxChange(event: any) {

    console.log(event.target.checked);
    this.iswirdeCommunication = event.target.checked;
    if (event.target.checked) {
      this.f['gatewayName'].setValidators(Validators.required);
      this.f['gatewayName'].updateValueAndValidity;
      this.f['deviceId'].setValidators([Validators.required, Validators.pattern(this.onlynumber)]);
      this.f['deviceId'].updateValueAndValidity;

    } else {
      this.f['gatewayName'].clearValidators()
      this.f['gatewayName'].updateValueAndValidity;
      this.f['deviceId'].clearValidators();
      this.f['deviceId'].updateValueAndValidity;

    }


  }
  selectedGateway: any
  selectionChange(event: any) {
    console.log(event)
    this.selectedGateway = event
  }
  patchForm(): void {
    // console.log(this.umdData)
    // this.sharedService.getAllConfigData(true).subscribe((data) => {
    //   if (
    //     data.statusCode == 200 &&
    //     data.message == 'Config data retrieved successfully.'
    //   ) {
    // this.configData = data;

    //console.log(this.umdData.valveRelayStatus)
    this.iswirdeCommunication = this.umdData?.isWiredCommunicationDevice ? true : false;
    if (this.iswirdeCommunication) {
      this.f['gatewayName'].setValidators(Validators.required);
      this.f['gatewayName'].updateValueAndValidity;
      this.f['deviceId'].setValidators([Validators.required, Validators.pattern(this.onlynumber)]);
      this.f['deviceId'].updateValueAndValidity;
    } else {
      this.f['gatewayName'].clearValidators();
      this.f['gatewayName'].updateValueAndValidity;
      this.f['deviceId'].clearValidators();
      this.f['deviceId'].updateValueAndValidity;
    }
    this.selectedGateway = {
      id: this.umdData?.gatewayInformationId ?? null,
      serialNumber: this.umdData?.gatewayName ?? null
    }


    this.selectedutlity = this.umdData?.utility;
    //console.log(this.configData?.suppliers)
    this.waterTypeItems = this.configData?.suppliers.filter((item: any) => {
      return item.master.name.toLowerCase() === this.selectedutlity.name.toLowerCase();
    });

    if (this.umdData?.utility?.id) {
      console.log(this.umdData)
      this.selectedutlity = this.umdData?.utility
      this.createUMDForm.patchValue({
        utilityMasterId: this.umdData?.utility?.id
          ? this.umdData?.utility?.id
          : '',
        macAddress: this.umdData?.macAddress ? this.umdData?.macAddress : '',
        meterSerialNo: this.umdData?.meterSerialNo ?? '',
        status: this.umdData?.status ? this.umdData?.status : '',
        mcbMainPower: this.umdData?.mcbMainPower ?? '',
        supplierId: this.umdData?.supplierId ?? null,
        gatewayName: this.umdData?.gatewayInformationId ?? null,
        isWiredCommunicationDevice: this.umdData?.isWiredCommunicationDevice ?? false,
        valveRelaystatus: this.umdData.valveRelayStatus == true ? "on" : 'Off',
        deviceId: this.umdData.deviceId
      });
      this.cdr.detectChanges();
    }
    //
    //   }
    //   console.log('Types', this.configData.data.types);
    // });
  }

  selectedutlity: any
  changeUtility(event: any) {
    console.log(event)
    this.selectedutlity = event;
    this.waterTypeItems = [];
    this.createUMDForm.controls['supplierId'].setValue('');
    //console.log(this.configData?.suppliers)
    this.waterTypeItems = this.configData?.suppliers.filter((item: any) => {
      return item.master.name.toLowerCase() === event.name.toLowerCase();
    });
    //console.log(this.waterTypeItems)

  }
}
