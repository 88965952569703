import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LegendItemClickEvent,
  CategoryAxis,
  Legend,
  Series,
  SeriesLabelsContentArgs,
} from '@progress/kendo-angular-charts';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared/shared.service';

import {
  getSuperAdminDashboard,
  getSuperAdminDashboardRevenuDetials,
  getSuperAdminDashboardRevenuDetials_BasedOn_Date,
  resetSuperAdminDashboardState,
} from '../../store/dashboard/dashboard.actions';
import {
  getSuperadmindashboardResponse,
  getSuperadmindashboardRevenueOnDateResponse,
  getSuperadmindashboardRevenueResponse,
} from '../../store/dashboard/dashboard-selector';
import { formatDate, JsonPipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {
  superadmin_graphColors,
  monthColors,
} from '../../shared/constants/enum';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';
import { LoaderService } from '../../core/services/loader.service';
export interface Project {
  projectId: string;
  projectName: string;
}
@Component({
  selector: 'app-rp-dashboard',
  templateUrl: './rp-dashboard.component.html',
  styleUrl: './rp-dashboard.component.scss',
})
export class RpDashboardComponent {
  private getSuperadmindashboardResponse: Subscription = new Subscription();
  superAdminDashboardData: any;
  commStatusTotalCount: number = 0;
  gatewayStatusTotalCount: number = 0;
  totalResidentialUnits: number = 0;
  totalConsumers: number = 0;
  superAdminDashboardRevenueData: any = [];
  revenueData: any = [];
  categories: string[] = [];
  totalRevenueAmount: number = 0;
  datePickerConfig: Partial<BsDatepickerConfig>;
  superAdminDashboardRevenueDataBasedonDate: any;
  public selectedDateRange: { startDate: string; endDate: string, projectIds: [] } | null =
    null;

  public filterProjectBillDates: any
  revenuesProjectsList: any = [];
  revenuTotalProjectRevenueData: any = [];
  configData: any;
  constructor(private store: Store, private sharedService: SharedService, private loaderService: LoaderService) {


    this.getAllConfigData();
    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
        minDate: sixMonthsAgo, // Set minimum date to 6 months ago
        maxDate: today // Set maximum date to today
      }
    );
  }
  private _diff: number = 0;
  _days: number = 0;
  _hours: number = 0;
  _minutes: number = 0;
  _seconds: number = 0;
  private _trialEndsAt: any;
  public seriesVisible = true;
  public residentialUnitChart: any;
  selectedProject: Project[] = [];
  selectedRevenuWiseProject: any = [];
  dateRange: boolean = false;
  projectsList: Project[] = [];
  communicationStatusGraphData: any = [];
  GatewayInfoGraphData: any = [];
  residentialUnitsData: any = [];
  consumerData: any = [];
  selectedRange = 'Current Month';
  colors = superadmin_graphColors;
  monthColors = monthColors;
  selectedBalance: string = 'total';
  selectedUtilityCommunication: any = 'All'
  balanceOptions = [
    { value: 'total', label: 'Total' },
    { value: 'postpaid', label: 'Postpaid' },
    { value: 'prepaid', label: 'Prepaid' }
  ];

  ngOnInit(): void {

    this.getDashboardData();

    this.getRevenuedata();
    this.selectionChange('Current Month');
    // setTimeout(() => {
    //   this.communicationStatusData();
    //   this.gateWayInformationData();
    //   this.getResidentialUnitData();
    //   this.listOfConsumers();
    //   this.getRevenuedata();
    //   this.selectionChange('This Month');
    // }, 500);
  }

  ngAfterViewInit() {

    this.getStoreInfo();
  }
  // onUtilityChange(){
  //   alert(this.selectedUtilityCommunication+this.selectedProject)
  //   if(this.selectedProject.length>0){

  //   }
  // }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  onDateRangeSelected(dateRange: (Date | undefined)[] | undefined): void {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0];
      const endDate = dateRange[1];

      // Format the dates to the desired format
      this.selectedDateRange = {
        startDate: formatDate(
          startDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          'en-US'
        ),
        endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
        projectIds: []
      };

      this.getRevenuedataBasedOnDate();
    } else {
      this.selectedDateRange = {
        startDate: '',
        endDate: '',
        projectIds: []
      };
      this.superAdminDashboardRevenueDataBasedonDate = []
      // this.getRevenuedataBasedOnDate();
    }
  }

  getStoreInfo() {
    let getSuperadmindashboardResponse$ = this.store.pipe(
      select(getSuperadmindashboardResponse)
    );

    let getSuperadmindashboardRevenueResponse$ = this.store.pipe(
      select(getSuperadmindashboardRevenueResponse)
    );

    let getSuperadmindashboardRevenueOnDateResponse$ = this.store.pipe(
      select(getSuperadmindashboardRevenueOnDateResponse)
    );

    this.getSuperadmindashboardResponse = this.store.pipe(select(getSuperadmindashboardResponse)).subscribe((res) => {
      if (res && res.statusCode == 200) {
        this.superAdminDashboardData = res.data;
        this.loaderService.setLoading(true)
        this.communicationStatusData();
        this.gateWayInformationData();
        this.getResidentialUnitData();
        this.listOfConsumers();
        // this.getRevenuedata();
        // this.selectionChange('Current Month');

        // console.log(this.projectsList);
      }

    })

    /*   getSuperadmindashboardResponse$.subscribe((res: any) => {
       console.log("")
       
       console.log('superAdminDashboardData', res);
     }); */

    getSuperadmindashboardRevenueResponse$.subscribe((res: any) => {
      if (res && res.statusCode == 200) {
        this.superAdminDashboardRevenueData = res.data;
        // this.processRevenueData(
        //   this.superAdminDashboardRevenueData.monthlyDateWiseResult.slice(-6)
        // );
        this.revenuTotalProjectRevenueData = this.superAdminDashboardRevenueData.monthlyBillSummary
        this.revenuesProjectsList = this.superAdminDashboardRevenueData.projectWiseRechargeData;
        this.revenuData();
        this.totalRevenueAmount =
          this.superAdminDashboardRevenueData.totalRevenueAmount;
      }

      console.log('getSuperadmindashboardRevenueResponse', res);
    });

    getSuperadmindashboardRevenueOnDateResponse$.subscribe((res: any) => {
      if (res && res.statusCode == 200) {
        this.superAdminDashboardRevenueDataBasedonDate = res.data;
      }
      console.log('getSuperadmindashboardRevenueOnDateResponse', res);
    });
  }

  public getColor(index: number): string {
    // console.log(this.monthColors[this.categories[index]]);
    return this.monthColors[this.categories[index]];
  }

  trackByUtilityId(index: number, utility: any): string {
    return utility.id;
  }
  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data?.data;
        this.projectsList = [...this.configData?.projects];
      }
      console.log('Types', this.projectsList);
    });
  }





  ngOnDestroy() {
    this.store.dispatch(resetSuperAdminDashboardState());
    this.getSuperadmindashboardResponse.unsubscribe();
  }
  onChangeRevenueProject(data: any) {
    console.log(data);

  }

  revenuDataOnProject() {
    let projectsList: any = this.selectedRevenuWiseProject
    this.store.dispatch(getSuperAdminDashboardRevenuDetials({ projectsList }));
  }
  public revenuData() {
    // Initialize a map to store revenue data by month
    const monthlyRevenueMap = new Map<string, number>();

    // If no project is selected, use the full data
    if (this.selectedRevenuWiseProject.length === 0) {

      this.superAdminDashboardRevenueData.monthlyBillSummary.forEach((entry: any) => {
        const month = entry.monthName;
        const revenueAmount = entry.totalBillAmount;

        // Accumulate revenue for each month
        if (monthlyRevenueMap.has(month)) {
          monthlyRevenueMap.set(
            month,
            monthlyRevenueMap.get(month)! + revenueAmount
          );
        } else {
          monthlyRevenueMap.set(month, revenueAmount);
        }
      });
    } else {
      // Loop through selected projects and accumulate revenue month-wise
      this.superAdminDashboardRevenueData.projectWiseRechargeData.forEach((revenuProjet: any) => {
        if (revenuProjet) {
          let revenueDataJson = revenuProjet.monthlyRecharges;
          revenueDataJson.forEach((entry: any) => {
            const month = entry.monthName;
            const revenueAmount = entry.totalRechargeAmount;

            // Accumulate revenue for each month
            if (monthlyRevenueMap.has(month)) {
              monthlyRevenueMap.set(
                month,
                monthlyRevenueMap.get(month)! + revenueAmount
              );
            } else {
              monthlyRevenueMap.set(month, revenueAmount);
            }
          });
        }
      });
    }

    // Convert the map into an array for chart data in the desired JSON format
    this.revenueData = Array.from(monthlyRevenueMap.entries()).map(
      ([month, amount], index) => ({
        category: month,    // Use month as the category
        value: amount.toFixed(2),      // The total revenue for that month
        colorString: monthColors[month] // Color based on the month
      })
    );
    console.log(this.revenueData);
  }





  // getUniqueProjects(data: any): Project[] {
  //   const projectsMap = new Map<string, string>();


  //   const addProjects = (projectCounts: { projectId: string; projectName: string }[]) => {
  //     projectCounts.forEach((project) => {
  //       projectsMap.set(project.projectId, project.projectName);
  //     });
  //   };

  //   if (data.communicationData && data.communicationData.projectCounts) {
  //     addProjects(data.communicationData.projectCounts);
  //   }
  //   if (data.consumersData && data.consumersData.projectCounts) {
  //     addProjects(data.consumersData.projectCounts);
  //   }
  //   if (data.gatewayData && data.gatewayData.projectCounts) {
  //     addProjects(data.gatewayData.projectCounts);
  //   }
  //   if (data.residentialUnitData && data.residentialUnitData.projectCounts) {
  //     addProjects(data.residentialUnitData.projectCounts);
  //   }

  //   // Convert the map to an array of unique projects
  //   return Array.from(projectsMap, ([projectId, projectName]) => ({
  //     projectId,
  //     projectName,
  //   })) as Project[]; // Assert the type
  // }


  getDashboardData() {
    let projectsList: any = []
    this.store.dispatch(resetSuperAdminDashboardState());
    this.store.dispatch(getSuperAdminDashboard({ projectsList }));
  }


  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  clearDate() {
    this.filterProjectBillDates = null; // Clear the selected date
  }

  onChangeProject(data: any) {
    console.log(data)
    let projectsList = this.selectedProject

    this.store.dispatch(getSuperAdminDashboard({ projectsList }));
    // this.store.dispatch(getSuperAdminDashboardRevenuDetials({projectsList}));
    // this.selectedProject = data;
    // this.communicationStatusData();
    // this.gateWayInformationData();
    // this.getResidentialUnitData();
    // this.listOfConsumers();
  }

  communicationStatusData() {
    // Initialize counts
    let commCount = 0;
    let actualNonCommuCount = 0;
    let nonCommInactiveCount = 0;
    let nonCommMCBOffCount = 0;
    this.commStatusTotalCount = 0;

    // Selected utility name (replace this with your actual selected utility logic)
    const selectedUtilityName = this.selectedUtilityCommunication;
    this.communicationStatusGraphData = []
    if (this.selectedProject.length === 0) {
      // Use total counts if no project is selected
      if (selectedUtilityName == 'All') {

        this.superAdminDashboardData?.communicationData?.totalCounts.forEach((utility: any) => {
          this.commStatusTotalCount += utility.totalCount;
          commCount += utility.commCount;
          actualNonCommuCount += utility.actualNonCommuCount;
          nonCommInactiveCount += utility.nonCommInActiveCount;
          nonCommMCBOffCount += utility.nonCommMCBOffCount;

          console.log(`Utility: ${utility.utilityMasterName}`);
          console.log(`Total Count: ${this.commStatusTotalCount}, Comm Count: ${commCount}`);
          console.log(`Actual Non-Comm Count: ${actualNonCommuCount}`);
          console.log(`Non-Comm Inactive Count: ${nonCommInactiveCount}`);
          console.log(`Non-Comm MCB Off Count: ${nonCommMCBOffCount}`);
        });
      } else {
        const utility = this.superAdminDashboardData.communicationData.totalCounts.find(
          (proj: any) => proj?.utilityMasterId === selectedUtilityName
        );

        console.log('utility', selectedUtilityName)
        commCount = utility.commCount;
        actualNonCommuCount = utility.actualNonCommuCount;
        nonCommInactiveCount = utility.nonCommInActiveCount;
        nonCommMCBOffCount = utility.nonCommMCBOffCount;
        this.commStatusTotalCount = utility.totalCount; // Ensure this is defined appropriately
      }
    } else {
      // Aggregate counts based on selected projects and utilityName
      console.log(' this.selectedProject', this.selectedProject)
      this.selectedProject.forEach((projectId: any) => {
        const project = this.superAdminDashboardData.communicationData.projectCounts.find(
          (proj: any) => proj.projectId === projectId
        );
        console.log(project, selectedUtilityName);


        if (project && selectedUtilityName != 'All') {
          // Filter the device counts based on the selected utility name
          project.deviceCounts.forEach((device: any) => {
            if (device.utilityMasterId.toUpperCase() === selectedUtilityName.toUpperCase()) {
              commCount += device.commCount;
              actualNonCommuCount += device.actualNonCommuCount;
              nonCommInactiveCount += device.nonCommInActiveCount;
              nonCommMCBOffCount += device.nonCommMCBOffCount;
              this.commStatusTotalCount += device.totalCount; // Ensure this is defined appropriately
            }
            console.log(commCount, actualNonCommuCount, nonCommInactiveCount, nonCommMCBOffCount, this.commStatusTotalCount)
          });
        } else {
          commCount += project.commCount;
          actualNonCommuCount += project.actualNonCommuCount;
          nonCommInactiveCount += project.nonCommInActiveCount;
          nonCommMCBOffCount += project.nonCommMCBOffCount;
          this.commStatusTotalCount = project.totalCount + this.commStatusTotalCount;
        }
      });
    }

    // Update the communication status graph data
    let tempdata = [
      {
        name: "Communicating",
        data: [commCount],
        color: this.colors[1],
        stack: "a",
        comment: 'Meters actively communicating as of today'
      },
      {
        name: "Non Communicating - Actual",
        data: [actualNonCommuCount],
        color: this.colors[0],
        stack: "b",
        comment: 'Meters not communicating as of today'
      },
      {
        name: "Non Communicating - Inactive",
        data: [nonCommInactiveCount],
        color: this.colors[2],
        stack: "b",
        comment: 'Meters that have never been activated since installation'
      },
      {
        name: "Non Communicating - MCB Off",
        data: [nonCommMCBOffCount],
        color: this.colors[3],
        stack: "b",
        comment: 'The MCB is turned off manually'
      }
    ];

    console.log(selectedUtilityName)
    /*  tempdata.map((item: any) => {
       // Check the type and conditionally push the "MCB Off" item
       if (selectedUtilityName === '456dcf08-74c8-44fd-9b0b-e8939e48cd8d' || (selectedUtilityName !== '939f6e20-efe4-4557-8e04-fe09cdd1a3cf' && selectedUtilityName !== '41b704b4-a960-49ff-a0cb-3469484c3e9d')) {
         this.communicationStatusGraphData.push(item);
       } else if (selectedUtilityName !== '456dcf08-74c8-44fd-9b0b-e8939e48cd8d' && item.name !== "Non Communicating - MCB Off") {
         this.communicationStatusGraphData.push(item);
       }
     }) */



    if (selectedUtilityName === '456dcf08-74c8-44fd-9b0b-e8939e48cd8d') {
      this.communicationStatusGraphData = tempdata.filter((item: { name: string; }) => item.name === "Non Communicating - MCB Off" || item.name !== "Non Communicating - MCB Off");
    } else if (selectedUtilityName === 'All') {
      this.communicationStatusGraphData = [
        {
          name: "Communicating",
          data: [commCount],
          color: this.colors[1],
          stack: "a",
          comment: 'Meters actively communicating as of today'
        },
        {
          name: "Non Communicating - Actual",
          data: [actualNonCommuCount],
          color: this.colors[0],
          stack: "b",
          comment: 'Meters not communicating as of today'
        },
        {
          name: "Non Communicating - Inactive",
          data: [nonCommInactiveCount],
          color: this.colors[2],
          stack: "b",
          comment: 'Meters that have never been activated since installation'
        },
        {
          name: "Non Communicating - MCB Off",
          data: [nonCommMCBOffCount],
          color: this.colors[3],
          stack: "b",
          comment: 'The MCB is turned off manually'
        }
      ];
    } else if (selectedUtilityName === '939f6e20-efe4-4557-8e04-fe09cdd1a3cf' || selectedUtilityName === '41b704b4-a960-49ff-a0cb-3469484c3e9d') {

      this.communicationStatusGraphData = tempdata.filter((item: { name: string; }) => item.name !== "Non Communicating - MCB Off");
    }

    console.log(this.communicationStatusGraphData);
  }


  get chartHeight(): number {
    //218
    return this.communicationStatusGraphData.length === 3 ? 217 : 217;
  }

  gateWayInformationData() {
    this.gatewayStatusTotalCount = 0;
    let gatewayStatusInactiveCount = 0;

    if (this.selectedProject.length == 0) {
      this.gatewayStatusTotalCount =
        this.superAdminDashboardData.gatewayData.totalCounts.gatewayStatusTotalCount;
      gatewayStatusInactiveCount =
        this.superAdminDashboardData.gatewayData.totalCounts
          .gatewayStatusInactiveCount;
    } else {
      this.selectedProject.forEach((projectId: any) => {
        const project =
          this.superAdminDashboardData.gatewayData.projectCounts.find(
            (proj: any) => proj.projectId === projectId
          );
        if (project) {
          this.gatewayStatusTotalCount += project.totalCount;
          gatewayStatusInactiveCount += project.inactiveCount;
        }
      });
    }

    this.GatewayInfoGraphData = [
      {
        value: this.gatewayStatusTotalCount - gatewayStatusInactiveCount,
        color: this.colors[1],
        name: `Active`
        ,
      },
      {
        value: gatewayStatusInactiveCount,
        color: this.colors[0],
        name: `Inactive`,
      },
    ];
  }

  getResidentialUnitData() {
    this.totalResidentialUnits = 0;
    let occupiedUnits = 0;
    let unoccupiedUnits = 0;

    if (this.selectedProject.length == 0) {
      this.totalResidentialUnits =
        this.superAdminDashboardData.residentialUnitData.totalCounts.totalUnits;
      occupiedUnits =
        this.superAdminDashboardData.residentialUnitData.totalCounts
          .occupiedUnits;
      unoccupiedUnits =
        this.superAdminDashboardData.residentialUnitData.totalCounts
          .unoccupiedUnits;
    } else {
      this.selectedProject.forEach((projectId: any) => {
        const project =
          this.superAdminDashboardData.residentialUnitData.projectCounts.find(
            (proj: any) => proj.projectId === projectId
          );
        if (project) {
          this.totalResidentialUnits += project.totalUnits;
          occupiedUnits += project.occupiedUnits;
          unoccupiedUnits += project.unoccupiedUnits;
        }
      });
    }

    this.residentialUnitsData = [
      {
        name: `Occupied units`,
        value: occupiedUnits,
        color: this.colors[1],
      },
      {
        name: `Unoccupied units`,
        value: unoccupiedUnits,
        color: this.colors[0],
      },
    ];
  }

  listOfConsumers() {
    // Initialize the counts for owners and tenants
    let owners = 0, tenents = 0;
    this.totalConsumers = 0;

    // Check if 'All Projects' is selected
    if (this.selectedProject.length == 0) {
      // Add the counts for all owners and tenants
      owners = this.superAdminDashboardData.ownerTenentCounts.totalCounts.totalOwnerCount;
      tenents = this.superAdminDashboardData.ownerTenentCounts.totalCounts.totalTenantCount;
      this.totalConsumers = owners + tenents;

    } else {
      // Iterate through the selected projects
      console.log('this.selectedProject', this.selectedProject);
      this.selectedProject.forEach((projectId: any) => {
        // Find the project in the consumer data
        const project = this.superAdminDashboardData.ownerTenentCounts.projectCounts.find(
          (proj: any) => proj.projectId === projectId
        );

        console.log('Project data:', project);

        if (project) {
          // Accumulate the counts for the selected projects
          this.totalConsumers += project.ownerCount + project.tenantCount;
          owners += project.ownerCount;
          tenents += project.tenantCount;
        }
      });
    }

    // Update the consumerData array with the calculated values
    this.consumerData = [
      {
        name: `Owner`,
        value: owners,
        color: this.colors[1],  // Assuming the color array is already defined
      },
      {
        name: `Tenant`,
        value: tenents,
        color: this.colors[0],  // Assuming the color array is already defined
      },
    ];

    // Log the final consumer data
    console.log('consumerData:', JSON.stringify(this.consumerData));
  }


  getRevenuedata() {
    let projectsList: any = []
    this.store.dispatch(getSuperAdminDashboardRevenuDetials({ projectsList }));
  }

  getRevenuedataBasedOnDate() {
    this.store.dispatch(
      getSuperAdminDashboardRevenuDetials_BasedOn_Date({
        daterange: this.selectedDateRange,
      })
    );
  }

  // revenueData = [
  //   {
  //     data: [1, 4, 6, 4, 8],
  //     color: '#63ABFD',
  //     name: 'Revenue',
  //   },
  // ];
  // public categories: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May'];

  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.value;
  }

  // public residentialUnitsData = [
  //   { name: 'Occupied units', value: 200, color: '#63ABFD' },
  //   { name: 'Unoccupied units', value: 200, color: '#FFA5CB' },
  // ];

  // public consumerData = [
  //   { name: 'Active', value: 800, color: '#63ABFD' },
  //   { name: 'Inactive', value: 200, color: '#FFA5CB' },
  // ];

  public listItems: Array<string> = ['Project 1', 'Project 2', 'Project 3'];


  public months: Array<string> = [
    'Current Month',
    'Last Month',
    'Last Three Months',
    'Last Six Months',
    'Custom Range',
  ];



  legend: Legend = {
    position: 'bottom',
  };


  public onSeriesClick(e: SeriesClickEvent): void {
    console.log('Clicked on:', e);
    // alert(`You clicked on ${e.category}: ₹${e.value}`);
  }
  public onLegendItemClick(e: LegendItemClickEvent): void {
    /* Do not hide the series on legend click */
    e.preventDefault();

    /* Hide the series manually */
    this.seriesVisible = !this.seriesVisible;
  }




  getDays(t: number) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t: number) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t: number) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t: number) {
    return Math.floor((t / 1000) % 60);
  }


  selectionChange(event: any) {
    const selectedOption = event;
    this.dateRange = false;
    const now = new Date();
    let startDate: Date;
    let endDate: Date = now;


    this.filterProjectBillDates = null
    switch (selectedOption) {
      case 'Current Month':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = now; // Current date of the current month
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'Last Month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1); // First day of last month
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999);  // Last day of last month
        break;
      case 'Last Three Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999); // Last day of the previous month
        break;
      case 'Last Six Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999);  // Last day of the previous month
        break;
      /* case 'One Year':
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
        break; */
      case 'Custom Range':
        this.dateRange = true;
        // Implement custom range logic here
        return;
      default:
        startDate = now;
        endDate = now;
    }

    this.selectedDateRange = {
      startDate: formatDate(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
      endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
      projectIds: []
    };

    this.getRevenuedataBasedOnDate();
    console.log(this.selectedDateRange);
  }
}
