import { BillingType } from './../../../shared/constants/enum';
import { Helper } from './../../../core/helpers/helper';
import {
  getAdminmeteronOffHistory,
  getAdminmeteronOffHistoryExport,
  GetAllMeterDetails,

  getMeterDetails,

  meterRedaingExportReset,

  resetMeterDetailsState,
  resetState,
} from './../../../store/meters/meters.actions';
import { Component, ViewChild, Inject } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { metersData } from './meters-data';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import {
  process,
  State,
  toODataString,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
  SelectableSettings,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  getAdminmeteronOffHistoryExportResponse,
  getAdminmeteronOffHistoryResponse,
  getAllmeterConsumptionResponse,
  getmeterConsumptionResponse,
} from '../../../store/meters/meters-selector';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { table_pageSize } from '../../../shared/constants/templatesNames';

import { PdfexportService } from '../../../core/services/pdfexport.service';
import { Roles, SweetAlertIcon } from '../../../shared/constants/enum';
import { getConsumptionHistoryExport } from '../../../store/customer/consumer.action';
import { getconsumptionHistoryExportResponse } from '../../../store/customer/consumer-selector';
import { Subscription } from 'rxjs';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CdkStepper } from '@angular/cdk/stepper';
import { formatDate } from '@angular/common';  // <-- Import formatDate from common
import { NotificationService } from '../../../shared/notification.service';
import { LoaderService } from '../../../core/services/loader.service';
@Component({
  selector: 'app-rp-meters-view',
  templateUrl: './rp-meters-view.component.html',
  styleUrl: './rp-meters-view.component.scss',
})
export class RpMetersViewComponent {
  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  selectValue!: string[];
  breadCrumbItems: Array<{}> | undefined;

  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  meterViewDetails: any;
  selectableSettings = this.sharedService.selectableSettings;
  public pageSize = 10;
  public skip = 0;
  public MeterControlsgridData: GridDataResult = { data: [], total: 0 };
  currentPage: number = 1;
  meteronOffsearchedValue: any = '';
  meterOnOffqueryString: string = '';
  meterOnOfforderByQuery: string = '';
  meterOnOfftotalUserCount: number = 0;
  meterOnOffcurrentPage: number = 1;
  public MeterOnOffHostotrydData: GridDataResult = { data: [], total: 0 };
  public meteronOffpagesize = 10
  public buttonCount = 5;
  meterOnOffHistorysList: any[] = [];
  public meterOnOffHistoryPageSize = table_pageSize.pageSizes;
  public meterOnOffControlsState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };



  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;




  fromDate: any = [];
  meterOnOfffromDate: any = [];

  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  meterAllDetails: any[] = [];
  exportMeterOnOffHistory: any[] = []
  roles = Roles;
  currentUser: any;
  projectId: any;
  exportData: any = [];
  selectedDateRange: Date[] | undefined;
  readingQueryString: string = '';
  hidden!: boolean;
  private highconsumptionSubscription: Subscription;
  datePickerConfig: Partial<BsDatepickerConfig>;
  datePickerConfigMeterreadings: Partial<BsDatepickerConfig>;
  projectBillingType: string = '';
  getMeterOnOffHistoryResponse$: Subscription = new Subscription();
  exportgetMeterOnOffHistoryResponse$: Subscription = new Subscription();
  constructor(
    private store: Store,
    private sharedService: SharedService,
    private router: Router,
    private PdfexportService: PdfexportService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,

  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    if (
      this.currentUser?.currentUserRoleDetails?.role == this.roles.ADMIN &&
      (history.state.meterViewDetails == undefined ||
        history.state.meterViewDetails == null)
    ) {
      history.state.meterViewDetails = this.sharedService.getConsumptionViewDetails();
    }
    const today = new Date();
    const twoMonthsago = new Date();
    twoMonthsago.setMonth(today.getMonth() - 2);
    if (history.state.meterViewDetails) {
      this.meterViewDetails = JSON.parse(history.state.meterViewDetails);
      console.log(this.meterViewDetails?.id);
      this.getMeterDetails();
      this.getStoreInfo();
      this.breadCrumbItems = [
        { label: 'Meters', url: 'meters', active: true },
        {
          label: 'Meter Details',
        },
      ];
    } else {
      this.getMeterDetails();
      this.getStoreInfo();
      this.breadCrumbItems = [{ label: 'Consumption History' }];
    }

    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
      }
    );


    this.datePickerConfigMeterreadings = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
        minDate: twoMonthsago, // Set minimum date to 6 months ago
        maxDate: today
      }
    );
    this.highconsumptionSubscription = this.notificationService.highConsumptionnUpdateSubjectUpdate$.subscribe(data => {
      this.updateHighConsumptionData(data);
    });
  }

  updateHighConsumptionData(data: any) {
    this.searchedValue = data;
    if (this.searchedValue && this.stepper) {
      this.stepper.selectedIndex = 1;
    }
  }

  filterReadings(type: string) {
    if (type != 'filter') {
      this.selectedDateRange = undefined;
      this.readingQueryString = '';
      // this.getreadings();
    } else {
      // this.getreadings();
    }
  }


  handleMeterInfo(data: any) { }
  private getAllmeterConsumptionResponse$: Subscription = new Subscription();

  exportAll() {
    this.store.dispatch(resetMeterDetailsState());
    this.getAllMeterDetails();
  }
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  getStoreInfo() {
    this.getAllmeterConsumptionResponse$ = this.store.pipe(select(getAllmeterConsumptionResponse)).subscribe((res: any) => {
      if (res) {
        this.meterAllDetails = [...res.items];
        if (this.meterAllDetails.length > 0) {
          const transformedData = this.meterAllDetails.map((item: any) => ({
            'Device Sr.No': item.deviceSRNo,
            'Mac Address': item.macAddress,
            'Utility': item.utilityType,
            'Time Stamp': formatDate(item.currentDate, 'dd MMM yyyy hh:mm a', 'en-US'),
            'Meter Reading': item.meterReading,
            'EB Meter Reading ': item.meterReadingEB,
            'DG Meter Reading ': item.meterReadingDG,
            'On/Off Status': item.onOffStatus,
            "Battery Level": item.batteryLevel,
            'Battery Voltage': item.batteryVoltage, // Placeholder for region.name
            // 'Status Bit': item.statusBits, // Placeholder for bill date
            Occupant: item.occupant,


            // Placeholder for status
          }));
          this.PdfexportService.downloadExcel(transformedData, 'Meter Readings');
        } else {
          this.sharedService.showMessageDialog(
            'No data to display ',
            '',
            SweetAlertIcon.ERROR
          );
        }
      }
      console.log('MetersConfiguration', res);
    });
    //
    this.getMeterOnOffHistoryResponse$ = this.store.pipe(select(getAdminmeteronOffHistoryResponse)).subscribe({
      next: (res) => {

        console.log(res);
        if (res) {
          this.meterOnOffHistorysList = [...res.items];
          this.pageSize = res.pageSize;
          this.meterOnOfftotalUserCount = res.totalCount;
          this.meterOnOffloadGridTable();
        }
      }
    })





  }

  ngAfterViewInit(): void {
    const savedStepIndex = localStorage.getItem('selectedTabIndex');
    if (savedStepIndex !== null && this.stepper) {
      this.stepper.selectedIndex = +savedStepIndex;  // Convert string to number
      localStorage.removeItem('selectedTabIndex');  // Optionally clear it after use
    }

    if (this.searchedValue && this.stepper) {
      this.stepper.selectedIndex = 1;
    }

  }

  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  /*  meterOnOffopenCalendar() {
     alert("dhsakjdhd22222222222222222")
     // Trigger the click event on the input field to open the datepicker
     const dateInput = document.querySelector('.form-control.date3');
     dateInput?.dispatchEvent(new Event('click'));
   } */
  meterOnOffopenCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date3') as HTMLElement;
    if (dateInput) {
      dateInput.click(); // Trigger a native click event
    }
  }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  clearDate() {
    this.fromDate = [];// Clear the selected date

  }
  meterclearDate() {
    this.meterOnOfffromDate = []
  }

  ngOnInit(): void {
    this.selectedIndex = 0
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectBillingType = this.currentUser.project.billingType;
    this.projectId = this.currentUser?.project.id;

    if (this.currentUser?.currentUserRoleDetails?.role == this.roles.ADMIN) {
      this.getmeteronOffHistory();
    }

    // this.gridView = this.gridData;
  }
  ngOnDestroy() {
    this.getAllmeterConsumptionResponse$.unsubscribe();
    this.exportgetMeterOnOffHistoryResponse$.unsubscribe();
    //this.sharedService.setMeterViewDetails(null);
    this.store.dispatch(resetMeterDetailsState());
    this.store.dispatch(resetState());

    // Or set it to [] if you prefer an empty array
  }

  getmeteronOffHistory() {
    console.log(this.meterViewDetails?.id)
    console.log(this.meteronOffpagesize)
    // MeterId: this.meterViewDetails?.id,
    this.store.dispatch(
      getAdminmeteronOffHistory({
        pageIndex: this.meterOnOffcurrentPage,
        pageSize: this.meteronOffpagesize,
        searchTerm: this.meteronOffsearchedValue,
        filter: this.meterOnOffqueryString,
        orderBy: this.meterOnOfforderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
  }
  public onOffMetertFilterChange(filter: CompositeFilterDescriptor): void {
    this.meterOnOffControlsState.filter = filter;
    console.log(this.meterOnOffControlsState);
    const meterOnOffqueryString = this.sharedService.createQuery(this.meterOnOffControlsState);
    this.meterOnOffqueryString = this.sharedService.createQuery(this.meterOnOffControlsState);
    this.meterOnOffcurrentPage = 1
    this.getmeteronOffHistory()

    console.log(meterOnOffqueryString);
  }
  onOffSortChange(sort: SortDescriptor[]): void {
    this.meterOnOffControlsState.sort = sort;
    console.log(this.meterOnOffControlsState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.meterOnOffControlsState.sort
    );

    this.meterOnOfforderByQuery = orderByQuery;

    this.getmeteronOffHistory()
  }
  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  selectedRowdataOnOff: any = [];
  deselectedRowdataOnOff: any = [];
  onOffSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdataOnOff.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdataOnOff);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdataOnOff.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdataOnOff);
      });

      if (this.deselectedRowdataOnOff.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdataOnOff.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdataOnOff.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdataOnOff = filteredArray;
      }
    }
  }

  protected meterOnOffPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.selectedRowdataOnOff = [];
    this.skip = skip;
    this.meteronOffpagesize = take;
    this.meterOnOffcurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getmeteronOffHistory()

  }

  meterOnOffloadGridTable() {
    const state: any = {
      take: this.meteronOffpagesize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.meterOnOffHistorysList, state);
    this.MeterOnOffHostotrydData = {
      data: this.meterOnOffHistorysList,
      total: this.meterOnOfftotalUserCount,
    };

    this.MeterOnOffHostotrydData.data = this.MeterOnOffHostotrydData.data.map((item: any) => {
      return {
        ...item,
        Date: item.onOffDate != null ? this.dateFormateChnage(item.onOffDate) : ''

      }
    })
    this.loaderService.setLoading(false);


    //this.dateFormateChnage(item.onOffDate)
  }


  protected meterOnOffStateChange(state: DataStateChangeEvent): void {
    console.log("DLA")
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    //this.getmeteronOffHistory()
  }

  public MeterOnOffValueChange(ev: string): void {
    if (ev.length == 0) {
      this.meterOnOffPageChange({ skip: 0, take: this.meteronOffpagesize })
      this.getmeteronOffHistory();
    }
  }

  onSearchMeterOnOffUnits(): void {
    if (this.meteronOffsearchedValue.length > 0) {
      this.getmeteronOffHistory();
    }
  }

  public onFilterOnOff(value: Event): void {
    console.log(value);
    this.meterOnOffPageChange({ skip: 0, take: this.meteronOffpagesize })
    // const inputValue = value;
    // this.searchedValue = value;
    this.getmeteronOffHistory()
  }

  public onMeterExport(type: string) {
    if (type === 'Excel') {
      if (this.MeterOnOffHostotrydData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to export Meter On/Off History',
          '',
          SweetAlertIcon.ERROR
        );
      }
    }
  }

  getMeterDetails() {
    this.store.dispatch(
      getMeterDetails({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
  }

  getAllMeterDetails() {
    this.store.dispatch(
      GetAllMeterDetails({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
        MeterId: this.meterViewDetails?.id,
        FromDate: '',
        ToDate: '',
      })
    );
  }

  metreOnoFffilterBasedOnDate(flag: number) {
    if (flag == 0) {
      this.meterOnOfffromDate = [];
    }
    this.store.dispatch(
      getAdminmeteronOffHistory({
        pageIndex: this.meterOnOffcurrentPage,
        pageSize: this.meteronOffpagesize,
        searchTerm: this.meteronOffsearchedValue,
        filter: this.meterOnOffqueryString,
        orderBy: this.meterOnOfforderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[0]) : '',
        ToDate:
          this.meterOnOfffromDate.length > 0 ? Helper.formatDate(this.meterOnOfffromDate[1]) : '',
      })
    );
  }

  filterBasedOnDate(flag: number) {
    if (flag == 0) {
      this.fromDate = [];
    }
    this.store.dispatch(
      getMeterDetails({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
  }

  exportAllConsumptions() {
    this.store.dispatch(
      getConsumptionHistoryExport({
        ProjectId: this.projectId,
        criteria: 'all',
        MeterId: this.meterViewDetails?.id,
      })
    );
  }

  exportAllMeterOnOff() {
    this.store.dispatch(
      getAdminmeteronOffHistoryExport({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
        MeterId: this.meterViewDetails?.id,
      })
    );


    /* this.store.dispatch(
      getConsumptionHistoryExport({
        ProjectId: this.projectId,
        criteria: 'all',
        MeterId: this.meterViewDetails?.id,
      })
    ); */
  }
  public onMeterPdfExport() {
    this.hiddenGrid?.saveAsPDF();
  }

  selectedIndex: any
  onStepChange(event: any) {
    console.log(event.selectedIndex)
    this.selectedIndex = event.selectedIndex

  }

  exportAll_2() {
    this.store.dispatch(resetMeterDetailsState());
    console.log(this.selectedIndex)
    if (this.selectedIndex == 0) {
      this.exportAll();

    } else if (this.selectedIndex == 1 && this.projectBillingType != 'Postpaid') {

      this.exportAllConsumptions();

    } else if (this.selectedIndex == 1 && this.projectBillingType == 'Postpaid') {
      this.exportAllMeterOnOff();
      this.exportgetMeterOnOffHistoryResponse$ = this.store.pipe(select(getAdminmeteronOffHistoryExportResponse)).subscribe({
        next: (res) => {
          console.log(res)
          this.exportMeterOnOffHistory = [...res.items];

          if (res.items.length > 0) {
            this.exportMeterOnOffHistory = [...res.items];
            const transformedData = this.exportMeterOnOffHistory?.map(
              (item: any) => ({
                'Meter S.No': item.meterSrNo,
                'Meter Number': item.macAddress,
                'Meter Type': item.utilityType,
                'Occupant': item.occupant,
                'Meter OnOff Status': item.onOffStatus,
                'Date': this.dateFormateChnage(item.onOffDate)
              })
            );
            this.PdfexportService.downloadExcel(transformedData, 'MeterOnOff');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export Meter On/Off History',
              '',
              SweetAlertIcon.ERROR
            );
          }
        },
      })

    } else if (this.selectedIndex == 2) {
      this.exportAllMeterOnOff();
      this.exportgetMeterOnOffHistoryResponse$ = this.store.pipe(select(getAdminmeteronOffHistoryExportResponse)).subscribe({
        next: (res) => {
          console.log(res)
          this.exportMeterOnOffHistory = [...res.items];

          if (res.items.length > 0) {
            this.exportMeterOnOffHistory = [...res.items];
            const transformedData = this.exportMeterOnOffHistory?.map(
              (item: any) => ({
                'Meter S.No': item.meterSrNo,
                'Meter Number': item.macAddress,
                'Meter Type': item.utilityType,
                'Occupant': item.occupant,
                'Meter OnOff Status': item.onOffStatus,
                'Date': this.dateFormateChnage(item.onOffDate)

              })
            );
            this.PdfexportService.downloadExcel(transformedData, 'MeterOnOff');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export Meter On/Off History',
              '',
              SweetAlertIcon.ERROR
            );
          }
        },
      })

    }



  }


  dateFormateChnage(inputDate: any) {
    const date = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour format with AM/PM
    };
    const formattedDate = date.toLocaleString('en-GB', options).replace(',', '').replace(' ', ' ');
    return formattedDate
  }
}
