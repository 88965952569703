import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient, private router: Router) { }

  getSuperAdminDashboardData(projectList:any): Observable<any> {
    let url;
    let payload:any=projectList
    url = `${AppConfigData.super_adimin_dashboard_data}`;
    return this.http.post<any>(url,payload).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('super_adimin_dashboard_data failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getSuperAdminDashboardRevenueData(projectList:any): Observable<any> {
    let url, payload:any=projectList
    url = `${AppConfigData.super_adimin_dashboard_Revenue_data}`;
    return this.http.post<any>(url,payload).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('super_adimin_dashboard_Revenue_data failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getSuperAdminDashboardRevenueDataOnDate(daterange: any): Observable<any> {
    let url;
    url = `${AppConfigData.super_adimin_dashboard_Revenue_data_BasedonDate}`;
    return this.http.post<any>(url, daterange).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error(
          'super_adimin_dashboard_Revenue_data_BasedonDate failed:',
          error
        );
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }



  //admin dashboard 
  //ripple_super_admin_baseUrl


  getAdminDashboardData(id: any): Observable<any> {
    let url;
    url = `${AppConfigData.adimin_dashboard_data + id}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error(
          'adimin_dashboard_data_BasedonDate failed:',
          error
        );
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getAdminFaltwiseConsumptionDashboardData(flatwiseConsmptions: any): Observable<any> {
    let url;
    url = `${AppConfigData.adimin_Flatwise_consumption_dashboard_data}`;
    return this.http.post<any>(url, flatwiseConsmptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error(
          'adimin_dashboard_data_BasedonDate failed:',
          error
        );
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  getAdminConsumptionData(flatwiseConsmptions: any): Observable<any> {
    let url;
    url = `${AppConfigData.adimin__consumption_dashboard_data}`;
    return this.http.post<any>(url, flatwiseConsmptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error(
          'adimin_dashboard_data_BasedonDate failed:',
          error
        );
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }


  //consumer dashboard
  getconsumerpostpaidDashboard(consumerdata: any): Observable<any> {
    let url;
    url = `${AppConfigData.consumerpoatpaid_dashboard_data}`;
    return this.http.post<any>(url, consumerdata).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error(
          'adimin_dashboard_data_BasedonDate failed:',
          error
        );
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getconsumerpostpaidConsumptionDashboard(consumerdata: any): Observable<any> {
    let url;
    url = `${AppConfigData.consumerpoatpaidConsumption_dashboard_data}`;
    return this.http.post<any>(url, consumerdata).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error(
          'adimin_dashboard_data_BasedonDate failed:',
          error
        );
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getCconsumerMonthlyConsumptionData(consumerdata: any): Observable<any> {
    let url;
    url = `${AppConfigData.getConsumerMonthlyConsumptionData}`;
    return this.http.post<any>(url, consumerdata).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error(
          'adimin_dashboard_data_BasedonDate failed:',
          error
        );
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }


  getconsumerConnectionsData(consumerdata: any): Observable<any> {
    let url;
    url = `${AppConfigData.getconsumerConnectionsData}`;
    return this.http.post<any>(url, consumerdata).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error(
          'adimin_dashboard_data_BasedonDate failed:',
          error
        );
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }


}




